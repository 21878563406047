var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var DZ,Kma,Lma,Mma,Nma,Oma,JZ,Pma,Qma,Rma,LZ,Sma,Tma,Uma,QZ,RZ,Vma,Wma,Xma,ZZ,Yma,Zma,$ma,b_,ana,bna,cna,dna;DZ=function(){};$CLJS.EZ=function(a){return null!=a?$CLJS.Bc===a.ki?!0:a.Wc?!1:$CLJS.$a(DZ,a):$CLJS.$a(DZ,a)};Kma=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.FZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Lma=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Mma=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.GZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.HZ=new $CLJS.M(null,"database-id","database-id",1883826326);Nma=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.IZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Oma=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);JZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Pma=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Qma=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
Rma=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.KZ=new $CLJS.M(null,"dataset","dataset",1159262238);LZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Sma=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.MZ=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.NZ=new $CLJS.M(null,"definition","definition",-1198729982);Tma=new $CLJS.M(null,"state","state",-1988618099);
$CLJS.OZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Uma=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.PZ=new $CLJS.M("source","joins","source/joins",1225821486);QZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);RZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);
$CLJS.SZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);Vma=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.TZ=new $CLJS.M(null,"selected?","selected?",-742502788);Wma=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Xma=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.UZ=new $CLJS.M(null,"table-name","table-name",-2117866341);
$CLJS.VZ=new $CLJS.M("source","card","source/card",-139977973);$CLJS.WZ=new $CLJS.M(null,"active","active",1895962068);$CLJS.XZ=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.YZ=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);ZZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Yma=new $CLJS.M(null,"details","details",1956795411);
$CLJS.$Z=new $CLJS.M("source","fields","source/fields",-649667981);Zma=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.a_=new $CLJS.M("source","native","source/native",-1444604147);$ma=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);b_=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);
$CLJS.c_=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);ana=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);bna=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);cna=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);dna=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.d_=new $CLJS.M(null,"source-alias","source-alias",1652088724);
$CLJS.e_=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(QZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.nk,$CLJS.VZ,$CLJS.a_,$CLJS.GZ,$CLJS.IZ,$CLJS.$Z,$CLJS.pL,$CLJS.jL,$CLJS.PZ,$CLJS.MZ,$CLJS.OZ],null));$CLJS.X(ZZ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,dna],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.bL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cL,$CLJS.nL],null)],null));
$CLJS.X(JZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,ana],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.bL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.mi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[Oma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.mi],null)],null)],null));
$CLJS.X($CLJS.aL,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.xt,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.TK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.Zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yB,$CLJS.IE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.nL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.xs,$CLJS.Zj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gi,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.IE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gL,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.nL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fL,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.nL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.d_,
new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.zE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.zE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VK,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.c_,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.dL],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FZ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.zE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SZ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,new $CLJS.h(null,2,[$CLJS.$n,1,$CLJS.jk,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TZ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,
[Vma,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZZ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[cna,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JZ],null)],null)],null)],null));
$CLJS.X(b_,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UZ,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[Sma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[Rma,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yB,$CLJS.IE],null)],null)],null)],null)],null)],null));
$CLJS.X(LZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WZ,$CLJS.Os],null),new $CLJS.P(null,2,5,$CLJS.Q,[Tma,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UZ,$CLJS.zE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NZ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,b_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SK,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,
$CLJS.zE],null)],null)],null));
$CLJS.X(Zma,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.xt,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.oL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.dL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HZ,$CLJS.eL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XZ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.ik],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YZ,
new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.ik],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KZ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hL,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.YK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Mma,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,LZ],null)],null)],null)],null));
$CLJS.X(Wma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.xt,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.XK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.WK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hL,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Rt,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.zE],null)],null)],null));
$CLJS.X(Xma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.xt,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.ZK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.UK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hL,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Rt,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.zE],null)],null)],null));
$CLJS.X($ma,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.xt,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.$K],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.zE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,
1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.zE],null)],null)],null));
$CLJS.X(Lma,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.xt,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.lL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.eL],null),new $CLJS.P(null,3,5,$CLJS.Q,[Qma,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Yma,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.ik],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.e_,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Yi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mA,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,$CLJS.Yi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Uma,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iL,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.ik],null)],null)],null));
$CLJS.X(RZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.EZ},Nma,$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,$CLJS.vi,$CLJS.kk,$CLJS.qk],[Kma,bna,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.$q],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.EZ)?$CLJS.EZ.H:null]))],null));
$CLJS.X(Pma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.xt,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RZ],null)],null)],null)],null));