var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var ZL,$L,aM,Cha,Dha,eM,Eha,hM,iM,jM,kM,Fha,lM,mM,oM,pM,bM,qM,Gha,rM,Hha,sM,tM,uM,Iha,vM,wM,Jha,xM,Kha,yM,zM,Lha,BM,Mha,Nha,Oha,DM,EM,FM,GM,HM,IM,JM,KM,Pha,LM,MM,NM,PM,QM,Qha,RM,SM,TM,UM,Rha,VM,WM,XM,YM,Sha,ZM,$M,aN,bN,Tha,cN,Uha,dN,eN,fN,Vha,gN,hN,iN,jN,lN,mN,Wha,nN,oN,Xha,pN,qN,rN,sN,tN,Yha,uN,Zha,vN,wN,yN,$ha,zN,AN,BN,aia,EN,FN,bia,cia,GN,IN,JN,KN,dia,NN,ON,eia,fia,gia,PN,QN,RN,SN,TN,UN,hia,VN,iia,WN,XN,ZN,aO,bO,cO,dO,jia,kia,fO,lia,mia,gO,hO,iO,jO,kO,nia,lO,oia,pia,mO,nO,oO,pO,qO,
qia,ria,sia,rO,tia,sO,uia,uO,vO,via,xO,yO,zO,AO,wia,DO,xia,yia,EO,FO,GO,zia,HO,IO,JO,KO,Aia,MO,NO,OO,PO,QO,Bia,RO,SO,Cia,TO,UO,VO,WO,XO,YO,$O,Dia,aP,Eia,bP,cP,dP,Fia,Gia,Hia,fP,gP,hP,Iia,iP,jP,kP,lP,Jia,Kia,nP,Lia,Mia,oP,pP,qP,rP,sP,Nia,tP,uP,vP,wP,Oia,xP,yP,zP,AP,CP,Pia,DP,EP,Qia,FP,GP,HP,Ria,IP,Sia,KP,Tia,LP,MP,NP,OP,PP,QP,RP,Uia,Via,Wia,SP,Xia,TP,UP,Yia,Zia,VP,WP,XP,$ia,YP,aja,ZP,bja,cja,$P,dja,eja,fja,cQ,dQ,gja,eQ,fQ,hQ,hja,iQ,ija,kQ,lQ,mQ,jja,kja,nQ,oQ,pQ,qQ,lja,sQ,tQ,mja,uQ,vQ,wQ,xQ,yQ,zQ,AQ,
BQ,nja,CQ,DQ,oja,EQ,pja,FQ,GQ,HQ,qja,IQ,JQ,rja,sja,KQ,LQ,MQ,tja,uja,NQ,vja,wja,xja,PQ,yja,RQ,zja,Aja,SQ,TQ,Bja,Cja,Dja,UQ,VQ,WQ,XQ,YQ,ZQ,$Q,Eja,aR,bR,Fja,cR,Gja,Hja,Ija,dR,eR,fR,Jja,gR,hR,Kja,iR,Lja,jR,Mja,Nja,lR,mR,nR,oR,pR,Oja,qR,rR,Pja,sR,tR,uR,vR,wR,xR,yR,zR,Qja,AR,BR,DR,ER,FR,GR,HR,IR,JR,KR,LR,MR,Rja,NR,OR,PR,QR,RR,Sja,SR,TR,UR,VR,Tja,YR,Uja,Vja,$R,Wja,aS,Xja,bS,Yja,Zja,cS,$ja,dS,fS,gS,aka,bka,kS,lS,cka,dka,mS,nS,oS,pS,qS,eka,rS,sS,uS,vS,fka;
$CLJS.YL=function(a){var b=$CLJS.zo.g(a,null),c=$CLJS.tF(b,$CLJS.Ts,function(d){return $CLJS.yn(d,$CLJS.Cf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.ok,b,$CLJS.Ej,k,$CLJS.Zs,l],null):null}function e(k){return f.j(k,$CLJS.Cf,$CLJS.Cf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
ZL=function(a,b){return $CLJS.xd(a)?$CLJS.J.g(a,b):a};$L=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.Bn($CLJS.zo.g($CLJS.ok.h(a),null));if($CLJS.n(b)){var e=ZL($CLJS.zt.h(b),c);$CLJS.n(e)?(e=$CLJS.Jq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:ZL($CLJS.xt.h(b),c)}return null};aM=function(a,b){return $CLJS.vd(a)||$CLJS.Kl(a)?$CLJS.J.g(a,b):$CLJS.wd(a)?$CLJS.J.g($CLJS.Df(a),b):null};
Cha=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.ok),d=$CLJS.J.g(a,$CLJS.nj);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Zs,$CLJS.mea),f=$CLJS.J.j(b,$CLJS.hea,!0),k=$CLJS.J.g(b,$CLJS.kC),l=$CLJS.J.j(b,$CLJS.iC,$CLJS.gC),m=$L(a,$CLJS.Vq.h(c),k,b);if($CLJS.n(m))return m;m=$L(a,$CLJS.kt.h(c),k,b);if($CLJS.n(m))return m;m=$L(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=$L(a,function(){var t=$CLJS.go.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=$L(a,$CLJS.Vq.h(c),
l,b);if($CLJS.n(m))return m;m=$L(a,$CLJS.kt.h(c),l,b);if($CLJS.n(m))return m;d=$L(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=$L(a,function(){var t=$CLJS.go.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?$L(a,e.h?e.h($CLJS.eC):e.call(null,$CLJS.eC),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?$L(a,e.h?e.h($CLJS.eC):e.call(null,$CLJS.eC),l,b):f};
Dha=function(a,b,c){a=$CLJS.Q;var d=$CLJS.eg.g;var e=$CLJS.pl.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.ok),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.kC);l=$CLJS.J.j(l,$CLJS.iC,$CLJS.gC);k=$CLJS.Vq.h(k);f=ZL($CLJS.hC.h(k),f);f=$CLJS.n(f)?f:ZL($CLJS.hC.h(k),l);d=d.call($CLJS.eg,e,f);return new $CLJS.P(null,2,5,a,[d,Cha(b,c)],null)};
$CLJS.cM=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Ej);a=$CLJS.J.g(b,$CLJS.Zs);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.iea,$CLJS.Ri),f=$CLJS.J.j(d,$CLJS.kea,Dha);return $CLJS.n(a)?$CLJS.fb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Ri,m);l=e.h?e.h(l):e.call(null,l);return bM(k,c,t,l)},null,a):null};
eM=function(a,b,c){var d=$CLJS.HA($CLJS.q($CLJS.jE),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Oh.v($CLJS.jE,$CLJS.dM,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.fM=function(a){return eM($CLJS.zk,a,function(){return $CLJS.uF.h(a)})};Eha=function(){var a=gM;return eM($CLJS.Ts,a,function(){var b=$CLJS.uF.h(a),c=$CLJS.YL(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
hM=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,$CLJS.me(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};iM=function(a){if($CLJS.zd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,hM(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,hM(c)],null);default:return hM(a)}}else return hM(a)};
jM=function(a,b){var c=$CLJS.zd(b);return c?(c=$CLJS.A(b)instanceof $CLJS.M)?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c:c};
kM=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Ve(jM,a)],null),$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,a],null)],null)],null),function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,iM(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,iM(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.nt(2,2,b))}())],null)};Fha=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M?$CLJS.A(a):null};
lM=function(a){return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.Pi,Fha,$CLJS.xt,["valid instance of one of these MBQL clauses: ",$CLJS.It(", ",$CLJS.hf.g($CLJS.A,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.me(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.me(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
mM=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XL],null)],null)};$CLJS.nM={};oM={};pM={};$CLJS.dM=function dM(a,b,c){var e=$CLJS.y(b);b=$CLJS.A(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=dM.j?dM.j(k,f,c):dM.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
bM=function bM(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.A(f);var k=$CLJS.B(f),l=aM(b,c);f=$CLJS.n(a)?a:$CLJS.wd(b)?$CLJS.Cf:$CLJS.yd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.fC.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=aM(f,c),d=bM.v?bM.v(b,l,k,d):bM.call(null,b,l,k,d),$CLJS.Pl(c)&&$CLJS.wd(f)&&c>$CLJS.D(f)&&(b=$CLJS.df(c-$CLJS.D(f),null),b=$CLJS.gf.g(f,b),f=null==f||$CLJS.Dd(f)?b:$CLJS.eg.g($CLJS.jd(f),b)),null==f||$CLJS.Kl(f)?$CLJS.R.j(f,c,d):$CLJS.vd(f)?$CLJS.be.g(f,d):$CLJS.Se.g($CLJS.U,$CLJS.R.j($CLJS.Df(f),
c,d))):$CLJS.xd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jea],null),bM.v?bM.v(f,b,c,d):bM.call(null,f,b,c,d)):$CLJS.n($CLJS.fC.h($CLJS.pd(f)))?$CLJS.be.g(f,d):$CLJS.zd($CLJS.Fe(f))?f:$CLJS.od(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.fC,!0],null))};qM=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Gha=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
rM=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);Hha=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);sM=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);tM=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);uM=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
Iha=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);vM=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);wM=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Jha=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);xM=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
Kha=new $CLJS.M(null,"from","from",1815293044);yM=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);zM=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);Lha=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.AM=new $CLJS.M(null,"snippet-name","snippet-name",819240328);BM=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
Mha=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Nha=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.CM=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Oha=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);DM=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
EM=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);FM=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);GM=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);HM=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);IM=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
JM=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);KM=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Pha=new $CLJS.M(null,"lon-max","lon-max",1590224717);LM=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);MM=new $CLJS.r(null,"stddev","stddev",775056588,null);NM=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.OM=new $CLJS.M(null,"snippet","snippet",953581994);PM=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);QM=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Qha=new $CLJS.M(null,"lat-field","lat-field",-830652957);RM=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);SM=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
TM=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);UM=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Rha=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);VM=new $CLJS.M("location","country","location/country",1666636202);WM=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
XM=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);YM=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Sha=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);ZM=new $CLJS.M(null,"unary","unary",-989314568);$M=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
aN=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);bN=new $CLJS.r(null,"ceil","ceil",-184398425,null);Tha=new $CLJS.M(null,"lon-min","lon-min",-787291357);cN=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Uha=new $CLJS.M(null,"match","match",1220059550);dN=new $CLJS.r(null,"count-where","count-where",2025939247,null);eN=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
fN=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Vha=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);gN=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);hN=new $CLJS.r(null,"sum","sum",1777518341,null);iN=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);jN=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.kN=new $CLJS.M("date","range","date/range",1647265776);lN=new $CLJS.r(null,"between","between",-1523336493,null);mN=new $CLJS.M(null,"clause-form","clause-form",1820463737);Wha=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);nN=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);oN=new $CLJS.r(null,"field","field",338095027,null);Xha=new $CLJS.M(null,"segment-id","segment-id",1810133590);
pN=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);qN=new $CLJS.r(null,"not-null","not-null",313812992,null);rN=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);sN=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);tN=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Yha=new $CLJS.M(null,"template-tag","template-tag",310841038);
uN=new $CLJS.M(null,"invalid","invalid",412869516);Zha=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);vN=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);wN=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.xN=new $CLJS.M(null,"context","context",-830191113);yN=new $CLJS.r(null,"get-year","get-year",704520253,null);
$ha=new $CLJS.M(null,"format-rows?","format-rows?",992129486);zN=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);AN=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);BN=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.CN=new $CLJS.M(null,"parameters","parameters",-1229919748);aia=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.DN=new $CLJS.M(null,"card","card",-1430355152);EN=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);FN=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);bia=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);cia=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);GN=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.HN=new $CLJS.M("date","month-year","date/month-year",1948031290);
IN=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);JN=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);KN=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.LN=new $CLJS.M(null,"joins","joins",1033962699);dia=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.MN=new $CLJS.M(null,"source-field","source-field",933829534);NN=new $CLJS.r(null,"Field","Field",430385967,null);
ON=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);eia=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);fia=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);gia=new $CLJS.M(null,"items","items",1031954938);PN=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);QN=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
RN=new $CLJS.M(null,"more","more",-2058821800);SN=new $CLJS.M(null,"first-clause","first-clause",-20953491);TN=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);UN=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);hia=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);VN=new $CLJS.r(null,"contains","contains",-1977535957,null);
iia=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);WN=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);XN=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.YN=new $CLJS.M(null,"widget-type","widget-type",1836256899);ZN=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.$N=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
aO=new $CLJS.r(null,"is-null","is-null",-356519403,null);bO=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);cO=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);dO=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);jia=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.eO=new $CLJS.M(null,"required","required",1807647006);kia=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
fO=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);lia=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);mia=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);gO=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);hO=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);iO=new $CLJS.M("string","contains","string/contains",1602423827);jO=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
kO=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);nia=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);lO=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);oia=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);pia=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
mO=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);nO=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);oO=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);pO=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);qO=new $CLJS.r(null,"share","share",1051097594,null);
qia=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);ria=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);sia=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);rO=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);tia=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
sO=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);uia=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.tO=new $CLJS.M(null,"collection","collection",-683361892);uO=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);vO=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.wO=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);via=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);xO=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);yO=new $CLJS.r(null,"metric","metric",2049329604,null);zO=new $CLJS.r(null,"concat","concat",-467652465,null);AO=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.BO=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
wia=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.CO=new $CLJS.M("date","relative","date/relative",25987732);DO=new $CLJS.M("location","city","location/city",-1746973325);xia=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);yia=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);EO=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);FO=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
GO=new $CLJS.M("number","between","number/between",97700581);zia=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);HO=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);IO=new $CLJS.r(null,"sqrt","sqrt",370479598,null);JO=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);KO=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.LO=new $CLJS.M(null,"semantic_type","semantic_type",272485089);Aia=new $CLJS.M(null,"metric-id","metric-id",-686486942);MO=new $CLJS.r(null,"*","*",345799209,null);NO=new $CLJS.r(null,"+","+",-740910886,null);OO=new $CLJS.r(null,"-","-",-471816912,null);PO=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);QO=new $CLJS.M(null,"allowed-for","allowed-for",122724334);Bia=new $CLJS.M(null,"question","question",-1411720117);RO=new $CLJS.r(null,"asc","asc",1997386096,null);
SO=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Cia=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);TO=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);UO=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);VO=new $CLJS.r(null,"\x3c","\x3c",993667236,null);WO=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);XO=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
YO=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.ZO=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);$O=new $CLJS.M("string","ends-with","string/ends-with",302681156);Dia=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);aP=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Eia=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
bP=new $CLJS.r(null,"and","and",668631710,null);cP=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);dP=new $CLJS.r(null,"round","round",-645002441,null);Fia=new $CLJS.M(null,"to","to",192099007);$CLJS.eP=new $CLJS.M("date","single","date/single",1554682003);Gia=new $CLJS.M(null,"action-id","action-id",-1727958578);Hia=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);fP=new $CLJS.r(null,"exp","exp",1378825265,null);
gP=new $CLJS.r(null,"Filter","Filter",-424893332,null);hP=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);Iia=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);iP=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);jP=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);kP=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
lP=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.mP=new $CLJS.M(null,"source-table","source-table",-225307692);Jia=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);Kia=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);nP=new $CLJS.r(null,"floor","floor",-772394748,null);Lia=new $CLJS.M(null,"middleware","middleware",1462115504);
Mia=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);oP=new $CLJS.M(null,"requires-features","requires-features",-101116256);pP=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);qP=new $CLJS.M(null,"clause-name","clause-name",-996419059);rP=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);sP=new $CLJS.r(null,"now","now",-9994004,null);
Nia=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);tP=new $CLJS.r(null,"not","not",1044554643,null);uP=new $CLJS.r(null,"avg","avg",1837937727,null);vP=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);wP=new $CLJS.M(null,"max-results","max-results",-32858165);Oia=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);xP=new $CLJS.r(null,"case","case",-1510733573,null);
yP=new $CLJS.r(null,"distinct","distinct",-148347594,null);zP=new $CLJS.r(null,"get-second","get-second",-425414791,null);AP=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.BP=new $CLJS.M(null,"join-alias","join-alias",1454206794);CP=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Pia=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
DP=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);EP=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Qia=new $CLJS.M(null,"original","original",-445386197);FP=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);GP=new $CLJS.r(null,"abs","abs",1394505050,null);HP=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Ria=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
IP=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Sia=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.JP=new $CLJS.M(null,"date","date",-1463434462);KP=new $CLJS.M(null,"second-clause","second-clause",-461435645);Tia=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);LP=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
MP=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);NP=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);OP=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);PP=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);QP=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);RP=new $CLJS.r(null,"or","or",1876275696,null);
Uia=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Via=new $CLJS.M(null,"constraints","constraints",422775616);Wia=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);SP=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Xia=new $CLJS.M(null,"csv-download","csv-download",2141432084);TP=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
UP=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Yia=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Zia=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);VP=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);WP=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
XP=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);$ia=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);YP=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);aja=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);ZP=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);bja=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
cja=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);$P=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);dja=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);eja=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);fja=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.aQ=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.bQ=new $CLJS.M(null,"database","database",1849087575);cQ=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);dQ=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);gja=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);eQ=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
fQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.gQ=new $CLJS.M(null,"expressions","expressions",255689909);hQ=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);hja=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);iQ=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
ija=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);kQ=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);lQ=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);mQ=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);jja=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);kja=new $CLJS.M(null,"action","action",-811238024);
nQ=new $CLJS.r(null,"get-day","get-day",1768100384,null);oQ=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);pQ=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);qQ=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.rQ=new $CLJS.M(null,"native","native",-613060878);lja=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);sQ=new $CLJS.M(null,"page","page",849072397);
tQ=new $CLJS.r(null,"length","length",-2065447907,null);mja=new $CLJS.M(null,"dashboard","dashboard",-631747508);uQ=new $CLJS.r(null,"get-week","get-week",752472178,null);vQ=new $CLJS.r(null,"get-month","get-month",1271156796,null);wQ=new $CLJS.r(null,"dimension","dimension",-2111181571,null);xQ=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);yQ=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
zQ=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);AQ=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);BQ=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);nja=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);CQ=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);DQ=new $CLJS.r(null,"substring","substring",-1513569493,null);
oja=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);EQ=new $CLJS.M(null,"internal","internal",-854870097);pja=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);FQ=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);GQ=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
HQ=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);qja=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);IQ=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);JQ=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);rja=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
sja=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);KQ=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);LQ=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);MQ=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
tja=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);uja=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);NQ=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.OQ=new $CLJS.M(null,"template-tags","template-tags",1853115685);vja=new $CLJS.M(null,"public-question","public-question",629369976);wja=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
xja=new $CLJS.M(null,"binary","binary",-1802232288);PQ=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);yja=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.QQ=new $CLJS.M(null,"source-query","source-query",198004422);RQ=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);zja=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
Aja=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);SQ=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);TQ=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);Bja=new $CLJS.M(null,"executed-by","executed-by",-739811161);Cja=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
Dja=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);UQ=new $CLJS.M(null,"amount","amount",364489504);VQ=new $CLJS.r(null,"percentile","percentile",1039342775,null);WQ=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);XQ=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);YQ=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
ZQ=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);$Q=new $CLJS.r(null,"trim","trim",-1880116002,null);Eja=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);aR=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);bR=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);Fja=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
cR=new $CLJS.M("string","\x3d","string/\x3d",983744235);Gja=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Hja=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Ija=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);dR=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);eR=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
fR=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Jja=new $CLJS.M(null,"lat-min","lat-min",1630784161);gR=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);hR=new $CLJS.r(null,"inside","inside",-681932758,null);Kja=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);iR=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Lja=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);jR=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);Mja=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Nja=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.kR=new $CLJS.M(null,"card-id","card-id",-1770060179);lR=new $CLJS.M(null,"variadic","variadic",882626057);
mR=new $CLJS.r(null,"upper","upper",1886775433,null);nR=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);oR=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);pR=new $CLJS.r(null,"optional","optional",-600484260,null);Oja=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);qR=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
rR=new $CLJS.M(null,"sugar","sugar",-73788488);Pja=new $CLJS.M(null,"lat-max","lat-max",841568226);sR=new $CLJS.r(null,"power","power",702679448,null);tR=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);uR=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);vR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
wR=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);xR=new $CLJS.r(null,"median","median",-2084869638,null);yR=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);zR=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Qja=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);AR=new $CLJS.M(null,"y","y",-1757859776);BR=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.CR=new $CLJS.M(null,"binning","binning",1709835866);DR=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);ER=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);FR=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);GR=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);HR=new $CLJS.r(null,"Reference","Reference",2024574086,null);IR=new $CLJS.M(null,"b","b",1482224470);
JR=new $CLJS.M(null,"a","a",-2123407586);KR=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);LR=new $CLJS.r(null,"replace","replace",853943757,null);MR=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Rja=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);NR=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
OR=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);PR=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);QR=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);RR=new $CLJS.r(null,"segment","segment",675610331,null);Sja=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);SR=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
TR=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);UR=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);VR=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Tja=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.WR=new $CLJS.M(null,"order-by","order-by",1527318070);YR=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Uja=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Vja=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.ZR=new $CLJS.M(null,"condition","condition",1668437652);$R=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Wja=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);aS=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Xja=new $CLJS.M(null,"card-name","card-name",-2035606807);bS=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Yja=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Zja=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);cS=new $CLJS.r(null,"log","log",45015523,null);$ja=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
dS=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.eS=new $CLJS.M(null,"database_type","database_type",-54700895);fS=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);gS=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.hS=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.iS=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.jS=new $CLJS.M("date","all-options","date/all-options",549325958);
aka=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);bka=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);kS=new $CLJS.M("location","state","location/state",-114378652);lS=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);cka=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);dka=new $CLJS.M(null,"lon-field","lon-field",517872067);
mS=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);nS=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);oS=new $CLJS.M(null,"numeric","numeric",-1495594714);pS=new $CLJS.r(null,"variable","variable",1359185035,null);qS=new $CLJS.r(null,"lower","lower",-1534114948,null);eka=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
rS=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);sS=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.tS=new $CLJS.M(null,"limit","limit",-1355822363);uS=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);vS=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);fka=new $CLJS.M(null,"pulse","pulse",-244494476);var wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zE],null),xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IE],null),yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vE],null),zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rE],null),AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DE],null),BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nL],null),gka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dL],null),hka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YK],null),CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.NI],null),DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RI],null),ES=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.II],null),FS=new $CLJS.ah(null,new $CLJS.h(null,12,[$CLJS.qi,null,$CLJS.fu,null,$CLJS.pj,null,$CLJS.di,null,$CLJS.Hi,null,$CLJS.yj,null,$CLJS.mk,null,$CLJS.Gj,null,$CLJS.ki,null,$CLJS.Fj,null,$CLJS.ri,null,$CLJS.Bi,null],null),null),GS=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.sk,null,$CLJS.bH,null,$CLJS.di,null,$CLJS.xi,null,$CLJS.eH,null,$CLJS.jj,null,$CLJS.Vi,null],null),null),
HS=$CLJS.gu.g(FS,GS),ika=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.xt,"date bucketing unit"],null)],null),FS),jka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.xt,"time bucketing unit"],null)],null),GS),IS=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.xt,"datetime bucketing unit"],null)],null),HS),JS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rJ],null),kka=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,
1,[$CLJS.xt,"temporal extract unit"],null),$CLJS.fH,$CLJS.Bi,$CLJS.mk,$CLJS.cJ,$CLJS.xJ,$CLJS.yJ,$CLJS.Gj,$CLJS.Hi,$CLJS.xi,$CLJS.jj,$CLJS.cH],null),lka=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.xt,"datetime-diff unit"],null),$CLJS.bH,$CLJS.Vi,$CLJS.sk,$CLJS.fu,$CLJS.pj,$CLJS.yj,$CLJS.qi,$CLJS.ki],null),KS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.xt,"temporal-extract week extraction mode"],null),$CLJS.XI,$CLJS.fJ,$CLJS.kJ],null),LS=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.xt,"relative-datetime unit"],null),$CLJS.di,$CLJS.Vi,$CLJS.sk,$CLJS.fu,$CLJS.pj,$CLJS.yj,$CLJS.qi,$CLJS.ki],null),SS,nka,$S,aT,bT,cT,dT,eT,fT,RU,pka,SU,qka,rka,TU,ska,tka,uka;$CLJS.X(aN,kM($CLJS.gJ,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.$v],null),$CLJS.Ks],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,LS],null)])));var MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aN],null);
$CLJS.X(FP,kM($CLJS.eJ,$CLJS.H(["n",$CLJS.Ks,"unit",LS])));var mka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FP],null);
$CLJS.X(vN,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.xt,"valid :absolute-datetime clause",$CLJS.Pi,function(a){if($CLJS.Va(jM($CLJS.aJ,a)))a=uN;else{a=$CLJS.hd(a);var b=$CLJS.fM(CS);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.JP:$CLJS.hJ}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[uN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"not an :absolute-datetime clause"],null),$CLJS.Ue(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JP,kM($CLJS.aJ,
$CLJS.H(["date",CS,"unit",ika]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,kM($CLJS.aJ,$CLJS.H(["datetime",DS,"unit",IS]))],null)],null));var NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vN],null);$CLJS.X(KN,kM($CLJS.My,$CLJS.H(["time",ES,"unit",jka])));var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KN],null),PS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.xt,"date or datetime literal"],null),NS,DS,CS],null);
$CLJS.X(vM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.xt,"time literal"],null),OS,ES],null));$CLJS.X(mS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.xt,"temporal literal"],null),PS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vM],null)],null));var QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mS],null);
$CLJS.X(Fja,lM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof NS?new $CLJS.Gc(function(){return NS},$CLJS.od(eia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,EQ,$CLJS.oj,$CLJS.Ak,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),mQ,"metabase/mbql/schema.cljc",69,$CLJS.aJ,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:mQ}(),NS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof MS?new $CLJS.Gc(function(){return MS},$CLJS.od(aka,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,
$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DP,"metabase/mbql/schema.cljc",29,$CLJS.gJ,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gJ,$CLJS.tk,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.$v],null),$CLJS.Ks],null)),$CLJS.ti,$CLJS.U($CLJS.Qj,$CLJS.U(pR,aP))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(MS)?MS.H:null])):null));return $CLJS.n(a)?a:DP}(),MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof OS?new $CLJS.Gc(function(){return OS},$CLJS.od(cja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,EQ,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dS,"metabase/mbql/schema.cljc",27,$CLJS.My,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.My,$CLJS.My,$CLJS.U($CLJS.Qj,Eja),$CLJS.ti,$CLJS.U($CLJS.Qj,wia)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(OS)?OS.H:null])):null));return $CLJS.n(a)?a:dS}(),OS],null)])));
$CLJS.X(bS,kM($CLJS.Ej,$CLJS.H(["value",$CLJS.mi,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eS,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,wS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mD,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,xS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.xs,yS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ti,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,IS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,wS],null)],null)],null)],null)])));var RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bS],null);$CLJS.X(KO,kM($CLJS.vB,$CLJS.H(["expression-name",wS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,$CLJS.ik],null)])));
SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KO],null);
nka=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.KE);c=$CLJS.J.g(c,$CLJS.sE);return $CLJS.E.g(b,$CLJS.sE)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.KE);c=$CLJS.J.g(c,$CLJS.JE);return $CLJS.E.g(b,$CLJS.JE)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.xt,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KE,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.xt,"binning strategy"],null),$CLJS.sE,$CLJS.JE,$CLJS.di],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),zS],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.JE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,$CLJS.Cl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"bin width must be \x3e\x3d 0."],null),$CLJS.Te($CLJS.Xl)],null)],null)],null)],null)));$CLJS.TS=function TS(a){switch(arguments.length){case 1:return TS.h(arguments[0]);case 2:return TS.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.TS.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.yB);b=$CLJS.J.g(b,$CLJS.vG);return $CLJS.TS.g(a,b)};$CLJS.TS.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.zC(a,$CLJS.$C)?FS:$CLJS.zC(a,$CLJS.ZD)?GS:$CLJS.zC(a,$CLJS.zD)?HS:null:null;return $CLJS.n(a)?$CLJS.Hd(a,b):!0};$CLJS.TS.A=2;
$CLJS.X(qR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.xt,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yB,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,xS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,BS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,new $CLJS.h(null,
1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,IS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,wS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CR,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,nka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.TS],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Te($CLJS.KE)],null)],null));
$CLJS.X(SR,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.yB);return"string"===typeof c?b:!0}],null)],null)}(kM($CLJS.pG,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,BS,wS],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qR],null)],null)]))));var US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SR],null);
$CLJS.X(SP,lM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof SS?new $CLJS.Gc(function(){return SS},$CLJS.od(WN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GN,"metabase/mbql/schema.cljc",60,$CLJS.vB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.vB,$CLJS.iS,$CLJS.U($CLJS.Qj,cN),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(pR,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(SS)?SS.H:null])):null));return $CLJS.n(a)?a:GN}(),SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof US?new $CLJS.Gc(function(){return US},
$CLJS.od(nO,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.xr,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,$CLJS.vi,$CLJS.kk,$CLJS.qk],["0.39.0",$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oN,"metabase/mbql/schema.cljc",51,$CLJS.pG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(US)?US.H:null])):null));return $CLJS.n(a)?a:oN}(),US],null)])));
$CLJS.VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SP],null);$CLJS.X(qM,kM($CLJS.eG,$CLJS.H(["aggregation-clause-index",$CLJS.Ks,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,$CLJS.ik],null)])));var WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qM],null);
$CLJS.X(XM,lM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof WS?new $CLJS.Gc(function(){return WS},$CLJS.od(Cja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BR,"metabase/mbql/schema.cljc",23,$CLJS.eG,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.eG,cka,$CLJS.U($CLJS.Qj,$CLJS.Ks),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(pR,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:BR}(),WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof SS?new $CLJS.Gc(function(){return SS},
$CLJS.od(WN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GN,"metabase/mbql/schema.cljc",60,$CLJS.vB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.vB,$CLJS.iS,$CLJS.U($CLJS.Qj,cN),
$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(pR,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(SS)?SS.H:null])):null));return $CLJS.n(a)?a:GN}(),SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof US?new $CLJS.Gc(function(){return US},$CLJS.od(nO,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.xr,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,
$CLJS.vi,$CLJS.kk,$CLJS.qk],["0.39.0",$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oN,"metabase/mbql/schema.cljc",51,$CLJS.pG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(US)?US.H:null])):null));return $CLJS.n(a)?a:oN}(),US],null)])));
var XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XM],null),YS=new $CLJS.ah(null,new $CLJS.h(null,11,[$CLJS.Kt,null,$CLJS.HG,null,$CLJS.IF,null,$CLJS.NG,null,$CLJS.PF,null,$CLJS.sG,null,$CLJS.ZF,null,$CLJS.Qt,null,$CLJS.FG,null,$CLJS.rG,null,$CLJS.OG,null],null),null),ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UM],null);
$CLJS.X(xM,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Pi,function(a){return"string"===typeof a?$CLJS.Zj:$CLJS.n(jM(YS,a))?QM:$CLJS.n(jM($CLJS.Ej,a))?$CLJS.Ej:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,$CLJS.Zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[QM,ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,$CLJS.VS],null)],null));$S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xM],null);
aT=new $CLJS.ah(null,new $CLJS.h(null,26,[$CLJS.LF,null,$CLJS.wJ,null,$CLJS.es,null,$CLJS.sJ,null,$CLJS.pJ,null,$CLJS.cG,null,$CLJS.qt,null,$CLJS.HF,null,$CLJS.tJ,null,$CLJS.mJ,null,$CLJS.sG,null,$CLJS.KG,null,$CLJS.qJ,null,$CLJS.uG,null,$CLJS.NF,null,$CLJS.ZI,null,$CLJS.ds,null,$CLJS.QF,null,$CLJS.ZF,null,$CLJS.TI,null,$CLJS.Wx,null,$CLJS.$I,null,$CLJS.lJ,null,$CLJS.dJ,null,$CLJS.MG,null,$CLJS.nG,null],null),null);
bT=new $CLJS.ah(null,new $CLJS.h(null,9,[$CLJS.as,null,$CLJS.Vr,null,$CLJS.ys,null,$CLJS.As,null,$CLJS.Xr,null,$CLJS.GG,null,$CLJS.Fs,null,$CLJS.bs,null,$CLJS.Zr,null],null),null);cT=new $CLJS.ah(null,new $CLJS.h(null,17,[$CLJS.$n,null,$CLJS.EG,null,$CLJS.yG,null,$CLJS.qG,null,$CLJS.JG,null,$CLJS.LG,null,$CLJS.VF,null,$CLJS.aG,null,$CLJS.CM,null,$CLJS.WF,null,$CLJS.DG,null,$CLJS.wG,null,$CLJS.jk,null,$CLJS.Yx,null,$CLJS.jG,null,$CLJS.kG,null,$CLJS.CG,null],null),null);
dT=new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.iJ,null,$CLJS.VI,null,$CLJS.uJ,null,$CLJS.ds,null,$CLJS.oJ,null],null),null);eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iR],null);fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IN],null);$CLJS.gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KQ],null);$CLJS.hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oR],null);
$CLJS.X(vR,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.xt,"numeric expression argument",$CLJS.Pi,function(a){return"number"===typeof a?$CLJS.Zt:$CLJS.n(jM(aT,a))?lP:$CLJS.n(jM(cT,a))?$CLJS.eG:$CLJS.n(jM($CLJS.Ej,a))?$CLJS.Ej:$CLJS.pG}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zt,$CLJS.Cl],null),new $CLJS.P(null,2,5,$CLJS.Q,[lP,eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,$CLJS.hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pG,
$CLJS.VS],null)],null));var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vR],null);
$CLJS.X(FQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.xt,"datetime expression argument",$CLJS.Pi,function(a){return $CLJS.n(jM(cT,a))?$CLJS.eG:$CLJS.n(jM($CLJS.Ej,a))?$CLJS.Ej:$CLJS.n(jM(dT,a))?PN:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,$CLJS.hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[PN,$CLJS.gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,PS,$CLJS.VS],null)],null)],null));
var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FQ],null);
$CLJS.X(hQ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.xt,"expression argument",$CLJS.Pi,function(a){return"number"===typeof a?$CLJS.Zt:$CLJS.Cd(a)?$CLJS.Os:$CLJS.n(jM(bT,a))?xQ:$CLJS.n(jM(aT,a))?lP:$CLJS.n(jM(dT,a))?PN:"string"===typeof a?$CLJS.Zj:$CLJS.n(jM(YS,a))?QM:$CLJS.n(jM($CLJS.Ej,a))?$CLJS.Ej:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zt,$CLJS.Cl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.Os],null),new $CLJS.P(null,2,5,$CLJS.Q,[xQ,fT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[lP,eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[PN,$CLJS.gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,$CLJS.Zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[QM,ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,$CLJS.VS],null)],null));var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hQ],null);$CLJS.X(fQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.xt,"numeric expression arg or interval"],null),mka,iT],null));
var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fQ],null);$CLJS.X(IM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.xt,"int greater than zero or numeric expression",$CLJS.Pi,function(a){return"number"===typeof a?$CLJS.Zt:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zt,zS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,eT],null)],null));var oka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IM],null);
$CLJS.X(QN,kM($CLJS.sG,$CLJS.H(["a",kT,"b",kT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,kT],null)])));var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QN],null);$CLJS.X(uM,kM($CLJS.IF,$CLJS.H(["s",$S,"start",oka,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,iT],null)])));var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uM],null);$CLJS.X(yQ,kM($CLJS.Wx,$CLJS.H(["s",$S])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yQ],null);$CLJS.X(WM,kM($CLJS.FG,$CLJS.H(["s",$S])));
var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WM],null);$CLJS.X($M,kM($CLJS.OG,$CLJS.H(["s",$S])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$M],null);$CLJS.X(sN,kM($CLJS.HG,$CLJS.H(["s",$S])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sN],null);$CLJS.X(dO,kM($CLJS.Kt,$CLJS.H(["s",$S])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dO],null);$CLJS.X(NM,kM($CLJS.Qt,$CLJS.H(["s",$S])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NM],null);
$CLJS.X(uR,kM($CLJS.NG,$CLJS.H(["s",$S,"match",$CLJS.Zj,"replacement",$CLJS.Zj])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uR],null);$CLJS.X(rS,kM($CLJS.PF,$CLJS.H(["a",$S,"b",$S,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,$S],null)])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rS],null);$CLJS.X(TO,kM($CLJS.rG,$CLJS.H(["s",$S,"pattern",$CLJS.Zj])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TO],null);
$CLJS.X(QR,kM($CLJS.ds,$CLJS.H(["x",lT,"y",lT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,lT],null)])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QR],null);$CLJS.X(OR,kM($CLJS.qt,$CLJS.H(["x",iT,"y",lT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,lT],null)])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OR],null);$CLJS.X(NR,kM($CLJS.KG,$CLJS.H(["x",iT,"y",iT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,iT],null)])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NR],null);
$CLJS.X(PR,kM($CLJS.es,$CLJS.H(["x",iT,"y",iT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,iT],null)])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PR],null);$CLJS.X(eQ,kM($CLJS.nG,$CLJS.H(["x",iT])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eQ],null);$CLJS.X(YQ,kM($CLJS.LF,$CLJS.H(["x",iT])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YQ],null);$CLJS.X(uS,kM($CLJS.uG,$CLJS.H(["x",iT])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uS],null);$CLJS.X(jP,kM($CLJS.QF,$CLJS.H(["x",iT])));
var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jP],null);$CLJS.X(pP,kM($CLJS.NF,$CLJS.H(["x",iT,"y",iT])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pP],null);$CLJS.X(MP,kM($CLJS.cG,$CLJS.H(["x",iT])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MP],null);$CLJS.X(JM,kM($CLJS.HF,$CLJS.H(["x",iT])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JM],null);$CLJS.X(lQ,kM($CLJS.MG,$CLJS.H(["x",iT])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lQ],null);
$CLJS.X(iN,kM($CLJS.dJ,$CLJS.H(["datetime-x",jT,"datetime-y",jT,"unit",lka])));var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iN],null);$CLJS.X(AN,kM($CLJS.sJ,$CLJS.H(["datetime",jT,"unit",kka,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,KS],null)])));var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AN],null);$CLJS.X(rP,kM($CLJS.$I,$CLJS.H(["date",jT])));var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rP],null);$CLJS.X(TP,kM($CLJS.wJ,$CLJS.H(["date",jT])));
var MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TP],null);$CLJS.X(wM,kM($CLJS.TI,$CLJS.H(["date",jT])));var NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wM],null);$CLJS.X(sS,kM($CLJS.mJ,$CLJS.H(["date",jT,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,KS],null)])));var OT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sS],null);$CLJS.X(EO,kM($CLJS.lJ,$CLJS.H(["date",jT])));var PT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EO],null);$CLJS.X(NP,kM($CLJS.qJ,$CLJS.H(["date",jT])));
var QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NP],null);$CLJS.X(eN,kM($CLJS.tJ,$CLJS.H(["datetime",jT])));var RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eN],null);$CLJS.X(JO,kM($CLJS.pJ,$CLJS.H(["datetime",jT])));var ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JO],null);$CLJS.X(YM,kM($CLJS.ZI,$CLJS.H(["datetime",jT])));var TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YM],null);$CLJS.X(oO,kM($CLJS.VI,$CLJS.H(["datetime",jT,"to",JS,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,JS],null)])));
var UT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oO],null),VT=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.xt,"datetime arithmetic unit"],null),$CLJS.eH,$CLJS.bH,$CLJS.Vi,$CLJS.sk,$CLJS.fu,$CLJS.pj,$CLJS.yj,$CLJS.qi,$CLJS.ki],null);$CLJS.X(tM,kM($CLJS.oJ,$CLJS.H(["datetime",jT,"amount",iT,"unit",VT])));var WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tM],null);
$CLJS.X(RM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return kM(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.iJ));var XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RM],null);$CLJS.X(sM,kM($CLJS.uJ,$CLJS.H(["datetime",jT,"amount",iT,"unit",VT])));var YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sM],null);
$CLJS.X(KQ,lM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof xT?new $CLJS.Gc(function(){return xT},$CLJS.od(ZP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NO,"metabase/mbql/schema.cljc",51,$CLJS.ds,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ds,$CLJS.HE,$CLJS.U($CLJS.Qj,cQ),AR,$CLJS.U($CLJS.Qj,cQ),RN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Ni,cQ))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:NO}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof WT?new $CLJS.Gc(function(){return WT},
$CLJS.od(Nia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[hO,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UP,"metabase/mbql/schema.cljc",67,$CLJS.oJ,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.oJ,$CLJS.hJ,$CLJS.U($CLJS.Qj,nS),UQ,$CLJS.U($CLJS.Qj,
ZQ),$CLJS.ti,$CLJS.U($CLJS.Qj,gR)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(WT)?WT.H:null])):null));return $CLJS.n(a)?a:UP}(),WT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof YT?new $CLJS.Gc(function(){return YT},$CLJS.od(Gha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,
$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[hO,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lS,"metabase/mbql/schema.cljc",72,$CLJS.uJ,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uJ,$CLJS.hJ,$CLJS.U($CLJS.Qj,nS),UQ,$CLJS.U($CLJS.Qj,ZQ),$CLJS.ti,$CLJS.U($CLJS.Qj,gR)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(YT)?YT.H:null])):null));
return $CLJS.n(a)?a:lS}(),YT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof UT?new $CLJS.Gc(function(){return UT},$CLJS.od(Lja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.VI,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ON,"metabase/mbql/schema.cljc",71,$CLJS.VI,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.VI,$CLJS.hJ,$CLJS.U($CLJS.Qj,nS),Fia,$CLJS.U($CLJS.Qj,DR),Kha,$CLJS.U($CLJS.Qj,$CLJS.U(pR,DR))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(UT)?UT.H:null])):null));return $CLJS.n(a)?a:ON}(),UT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&
"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof XT?new $CLJS.Gc(function(){return XT},$CLJS.od(nja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.iJ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sP,"metabase/mbql/schema.cljc",
45,$CLJS.iJ,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.iJ],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(XT)?XT.H:null])):null));return $CLJS.n(a)?a:sP}(),XT],null)])));$CLJS.ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PP],null);$CLJS.X(yM,kM($CLJS.Fs,$CLJS.H(["first-clause",$CLJS.ZT,"second-clause",$CLJS.ZT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,$CLJS.ZT],null)])));var $T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yM],null);
$CLJS.X(kO,kM($CLJS.ys,$CLJS.H(["first-clause",$CLJS.ZT,"second-clause",$CLJS.ZT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,$CLJS.ZT],null)])));var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kO],null);$CLJS.X(TM,kM($CLJS.As,$CLJS.H(["clause",$CLJS.ZT])));
var bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TM],null),cU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,3,[$CLJS.xt,":field or :expression reference or :relative-datetime",$CLJS.zt,$CLJS.Ue(":field or :expression reference or :relative-datetime"),$CLJS.Pi,function(a){return $CLJS.n(jM($CLJS.gJ,a))?$CLJS.gJ:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gJ,MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,$CLJS.VS],null)],null);
$CLJS.X(HQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.xt,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ys,$CLJS.Os,$CLJS.Cl,$CLJS.Zj,QS,cU,kT,RS],null)],null));var dU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HQ],null);
$CLJS.X(YO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.xt,"order comparable",$CLJS.Pi,function(a){return $CLJS.n(jM($CLJS.Ej,a))?$CLJS.Ej:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ys,$CLJS.Cl,$CLJS.Zj,QS,kT,cU],null)],null)],null));var eU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YO],null);
$CLJS.X(GR,kM($CLJS.bs,$CLJS.H(["field",dU,"value-or-field",dU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,dU],null)])));var fU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GR],null);$CLJS.X(KR,kM($CLJS.GG,$CLJS.H(["field",dU,"value-or-field",dU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,dU],null)])));var gU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KR],null);$CLJS.X(FR,kM($CLJS.Zr,$CLJS.H(["field",eU,"value-or-field",eU])));
var hU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FR],null);$CLJS.X(ER,kM($CLJS.Vr,$CLJS.H(["field",eU,"value-or-field",eU])));var iU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ER],null);$CLJS.X(tN,kM($CLJS.as,$CLJS.H(["field",eU,"value-or-field",eU])));var jU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tN],null);$CLJS.X(zN,kM($CLJS.Xr,$CLJS.H(["field",eU,"value-or-field",eU])));var kU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zN],null);$CLJS.X(pQ,kM($CLJS.SF,$CLJS.H(["field",eU,"min",eU,"max",eU])));
var lU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pQ],null);$CLJS.X(RQ,kM($CLJS.YF,$CLJS.H(["lat-field",eU,"lon-field",eU,"lat-max",eU,"lon-min",eU,"lat-min",eU,"lon-max",eU])));var mU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RQ],null);$CLJS.X(cO,kM($CLJS.RF,$CLJS.H(["field",$CLJS.VS])));var nU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cO],null);$CLJS.X(SO,kM($CLJS.bG,$CLJS.H(["field",$CLJS.VS])));var oU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SO],null);$CLJS.X(uO,kM($CLJS.TF,$CLJS.H(["field",$CLJS.VS])));
var pU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uO],null);$CLJS.X(BM,kM($CLJS.$F,$CLJS.H(["field",$CLJS.VS])));var qU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BM],null),rU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xG,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Os],null)],null);$CLJS.X(HO,kM($CLJS.gG,$CLJS.H(["field",$S,"string-or-field",$S,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,rU],null)])));var sU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HO],null);
$CLJS.X(AP,kM($CLJS.AG,$CLJS.H(["field",$S,"string-or-field",$S,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,rU],null)])));var tU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AP],null);$CLJS.X(sO,kM($CLJS.oG,$CLJS.H(["field",$S,"string-or-field",$S,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,rU],null)])));var uU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sO],null);$CLJS.X(iQ,kM($CLJS.hG,$CLJS.H(["field",$S,"string-or-field",$S,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,rU],null)])));
var vU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iQ],null);$CLJS.X(KM,kM($CLJS.BG,$CLJS.H(["field",$CLJS.VS,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ks,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nk,$CLJS.$v,$CLJS.by,$CLJS.XF],null)],null),"unit",LS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Os],null)],null)],null)])));var wU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KM],null);
$CLJS.X(AQ,kM($CLJS.OF,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WK],null),wS],null)])));var xU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AQ],null);
$CLJS.X(IN,lM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},$CLJS.od(tR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bP,"metabase/mbql/schema.cljc",15,$CLJS.Fs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Fs,SN,$CLJS.U($CLJS.Qj,gP),KP,$CLJS.U($CLJS.Qj,gP),bO,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Ni,gP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n($T)?$T.H:null])):null));return $CLJS.n(a)?a:bP}(),$T],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},
$CLJS.od(OP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RP,"metabase/mbql/schema.cljc",14,$CLJS.ys,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ys,SN,$CLJS.U($CLJS.Qj,gP),KP,$CLJS.U($CLJS.Qj,gP),bO,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Ni,gP))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:RP}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof bU?new $CLJS.Gc(function(){return bU},$CLJS.od(BQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tP,"metabase/mbql/schema.cljc",15,$CLJS.As,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.hS,$CLJS.U($CLJS.Qj,gP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(bU)?bU.H:null])):null));return $CLJS.n(a)?a:tP}(),bU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==
typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(XP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VO,"metabase/mbql/schema.cljc",13,$CLJS.Zr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Zr,$CLJS.pG,$CLJS.U($CLJS.Qj,UN),eR,$CLJS.U($CLJS.Qj,UN)],
null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:VO}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(aS,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UO,"metabase/mbql/schema.cljc",14,$CLJS.as,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.as,$CLJS.pG,$CLJS.U($CLJS.Qj,UN),eR,$CLJS.U($CLJS.Qj,UN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:UO}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&
"undefined"!==typeof pM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od(VP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WO,"metabase/mbql/schema.cljc",13,$CLJS.Vr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Vr,$CLJS.pG,$CLJS.U($CLJS.Qj,
UN),eR,$CLJS.U($CLJS.Qj,UN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:WO}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.od(TR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],
[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cP,"metabase/mbql/schema.cljc",14,$CLJS.Xr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Xr,$CLJS.pG,$CLJS.U($CLJS.Qj,UN),eR,$CLJS.U($CLJS.Qj,UN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:cP}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&
"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.od(WP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XO,"metabase/mbql/schema.cljc",13,$CLJS.bs,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.bs,$CLJS.pG,$CLJS.U($CLJS.Qj,fS),eR,$CLJS.U($CLJS.Qj,fS),IQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Ni,fS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:XO}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.od(jN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,
$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FN,"metabase/mbql/schema.cljc",14,$CLJS.GG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.GG,$CLJS.pG,$CLJS.U($CLJS.Qj,fS),eR,$CLJS.U($CLJS.Qj,fS),IQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Ni,fS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(gU)?gU.H:null])):null));
return $CLJS.n(a)?a:FN}(),gU],null)])));
$CLJS.X(PP,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.xt,"valid filter expression",$CLJS.Pi,function(a){return $CLJS.n(jM(dT,a))?$CLJS.hJ:$CLJS.n(jM(aT,a))?oS:$CLJS.n(jM(YS,a))?$CLJS.Zj:$CLJS.n(jM(bT,a))?$CLJS.Os:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,$CLJS.gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[oS,eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,lM($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},$CLJS.od(tR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bP,"metabase/mbql/schema.cljc",
15,$CLJS.Fs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Fs,SN,$CLJS.U($CLJS.Qj,gP),KP,$CLJS.U($CLJS.Qj,gP),bO,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Ni,gP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n($T)?$T.H:null])):null));return $CLJS.n(a)?a:bP}(),$T],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.od(OP,new $CLJS.h(null,1,[$CLJS.bk,
!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RP,"metabase/mbql/schema.cljc",14,$CLJS.ys,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ys,SN,$CLJS.U($CLJS.Qj,gP),KP,$CLJS.U($CLJS.Qj,gP),bO,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Ni,gP))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(aU)?
aU.H:null])):null));return $CLJS.n(a)?a:RP}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof bU?new $CLJS.Gc(function(){return bU},$CLJS.od(BQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),tP,"metabase/mbql/schema.cljc",15,$CLJS.As,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.hS,$CLJS.U($CLJS.Qj,gP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(bU)?bU.H:null])):null));return $CLJS.n(a)?a:tP}(),bU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.od(WP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,
$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XO,"metabase/mbql/schema.cljc",13,$CLJS.bs,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bs,$CLJS.pG,$CLJS.U($CLJS.Qj,fS),eR,$CLJS.U($CLJS.Qj,fS),IQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Ni,fS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(fU)?fU.H:null])):
null));return $CLJS.n(a)?a:XO}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.od(jN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FN,"metabase/mbql/schema.cljc",14,$CLJS.GG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.GG,$CLJS.pG,$CLJS.U($CLJS.Qj,fS),eR,$CLJS.U($CLJS.Qj,fS),IQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Ni,fS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(gU)?gU.H:null])):null));return $CLJS.n(a)?a:FN}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},
$CLJS.od(XP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VO,"metabase/mbql/schema.cljc",13,$CLJS.Zr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Zr,$CLJS.pG,$CLJS.U($CLJS.Qj,UN),eR,$CLJS.U($CLJS.Qj,UN)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:VO}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od(VP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WO,"metabase/mbql/schema.cljc",13,$CLJS.Vr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Vr,$CLJS.pG,$CLJS.U($CLJS.Qj,UN),eR,$CLJS.U($CLJS.Qj,UN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:WO}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(aS,new $CLJS.h(null,
1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UO,"metabase/mbql/schema.cljc",14,$CLJS.as,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.as,$CLJS.pG,$CLJS.U($CLJS.Qj,UN),eR,$CLJS.U($CLJS.Qj,UN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(jU)?jU.H:null])):
null));return $CLJS.n(a)?a:UO}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.od(TR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),cP,"metabase/mbql/schema.cljc",14,$CLJS.Xr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Xr,$CLJS.pG,$CLJS.U($CLJS.Qj,UN),eR,$CLJS.U($CLJS.Qj,UN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:cP}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},$CLJS.od(eja,new $CLJS.h(null,
1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lN,"metabase/mbql/schema.cljc",19,$CLJS.SF,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.SF,$CLJS.pG,$CLJS.U($CLJS.Qj,UN),$CLJS.$n,$CLJS.U($CLJS.Qj,UN),$CLJS.jk,$CLJS.U($CLJS.Qj,UN)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:lN}(),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof sU?new $CLJS.Gc(function(){return sU},$CLJS.od(gja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),jR,"metabase/mbql/schema.cljc",23,$CLJS.gG,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gG,$CLJS.pG,$CLJS.U($CLJS.Qj,dR),EN,$CLJS.U($CLJS.Qj,dR),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(pR,zQ))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(sU)?sU.H:null])):null));return $CLJS.n(a)?a:jR}(),sU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof tU?new $CLJS.Gc(function(){return tU},
$CLJS.od(hia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JN,"metabase/mbql/schema.cljc",21,$CLJS.AG,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.AG,$CLJS.pG,$CLJS.U($CLJS.Qj,dR),EN,$CLJS.U($CLJS.Qj,dR),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(pR,zQ))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(tU)?tU.H:null])):null));return $CLJS.n(a)?a:JN}(),tU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof uU?new $CLJS.Gc(function(){return uU},$CLJS.od(uia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VN,"metabase/mbql/schema.cljc",20,$CLJS.oG,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.oG,$CLJS.pG,$CLJS.U($CLJS.Qj,dR),EN,$CLJS.U($CLJS.Qj,dR),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(pR,zQ))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(uU)?uU.H:null])):null));return $CLJS.n(a)?a:VN}(),uU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==
typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},$CLJS.od(Zja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zM,"metabase/mbql/schema.cljc",36,$CLJS.hG,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.hG,$CLJS.pG,$CLJS.U($CLJS.Qj,dR),EN,$CLJS.U($CLJS.Qj,dR),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(pR,zQ))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:zM}(),vU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof mU?new $CLJS.Gc(function(){return mU},$CLJS.od(Ija,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),
$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hR,"metabase/mbql/schema.cljc",26,$CLJS.YF,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.YF,Qha,$CLJS.U($CLJS.Qj,UN),dka,$CLJS.U($CLJS.Qj,UN),Pja,$CLJS.U($CLJS.Qj,UN),Tha,$CLJS.U($CLJS.Qj,UN),Jja,$CLJS.U($CLJS.Qj,UN),Pha,$CLJS.U($CLJS.Qj,
UN)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:hR}(),mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},$CLJS.od(via,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CP,"metabase/mbql/schema.cljc",28,$CLJS.TF,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,$CLJS.pG,$CLJS.U($CLJS.Qj,NN)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:CP}(),pU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&
"undefined"!==typeof qU?new $CLJS.Gc(function(){return qU},$CLJS.od(tja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",29,$CLJS.$F,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$F,$CLJS.pG,$CLJS.U($CLJS.Qj,NN)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(qU)?qU.H:null])):null));return $CLJS.n(a)?a:qQ}(),qU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof nU?new $CLJS.Gc(function(){return nU},$CLJS.od(Uja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aO,"metabase/mbql/schema.cljc",27,$CLJS.RF,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RF,$CLJS.pG,$CLJS.U($CLJS.Qj,NN)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:aO}(),nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&
"undefined"!==typeof oU?new $CLJS.Gc(function(){return oU},$CLJS.od(Hia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qN,"metabase/mbql/schema.cljc",28,$CLJS.bG,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bG,$CLJS.pG,$CLJS.U($CLJS.Qj,NN)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:qN}(),oU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof wU?new $CLJS.Gc(function(){return wU},$CLJS.od(Hha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PQ,"metabase/mbql/schema.cljc",33,$CLJS.BG,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.BG,$CLJS.pG,$CLJS.U($CLJS.Qj,NN),$CLJS.tk,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ks,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nk,$CLJS.$v,$CLJS.by,$CLJS.XF],null)],null)),$CLJS.ti,$CLJS.U($CLJS.Qj,aP),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(pR,jja))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(wU)?wU.H:null])):null));return $CLJS.n(a)?a:PQ}(),wU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof xU?new $CLJS.Gc(function(){return xU},$CLJS.od(Pia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),RR,"metabase/mbql/schema.cljc",27,$CLJS.OF,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OF,Xha,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,zja,cN],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(xU)?xU.H:null])):null));return $CLJS.n(a)?a:RR}(),xU],null)]))],null)],null));
$CLJS.X(CQ,kM($CLJS.ZF,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,1,[$CLJS.xt,":case subclause"],null),$CLJS.ZT,kT],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.xt,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),kT],null)],null)],null)])));
var yU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CQ],null);
$CLJS.X(iR,lM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof xT?new $CLJS.Gc(function(){return xT},$CLJS.od(ZP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NO,"metabase/mbql/schema.cljc",51,$CLJS.ds,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ds,$CLJS.HE,$CLJS.U($CLJS.Qj,cQ),AR,$CLJS.U($CLJS.Qj,cQ),RN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Ni,cQ))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:NO}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},
$CLJS.od(aja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OO,"metabase/mbql/schema.cljc",51,$CLJS.qt,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qt,$CLJS.HE,$CLJS.U($CLJS.Qj,ZQ),
AR,$CLJS.U($CLJS.Qj,cQ),RN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Ni,cQ))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:OO}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM?new $CLJS.Gc(function(){return zT},$CLJS.od($ia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,qP,$CLJS.Ui,$CLJS.oj,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],
[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.kA,"metabase/mbql/schema.cljc",$CLJS.KG,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.KG,$CLJS.HE,$CLJS.U($CLJS.Qj,ZQ),AR,$CLJS.U($CLJS.Qj,ZQ),RN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Ni,ZQ))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?
a:$CLJS.kA}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof AT?new $CLJS.Gc(function(){return AT},$CLJS.od(bja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MO,"metabase/mbql/schema.cljc",51,$CLJS.es,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.es,$CLJS.HE,$CLJS.U($CLJS.Qj,ZQ),AR,$CLJS.U($CLJS.Qj,ZQ),RN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Ni,ZQ))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:MO}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof mT?new $CLJS.Gc(function(){return mT},
$CLJS.od(MQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aR,"metabase/mbql/schema.cljc",58,$CLJS.sG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.sG,JR,$CLJS.U($CLJS.Qj,EM),IR,$CLJS.U($CLJS.Qj,
EM),RN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Ni,EM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:aR}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof oT?new $CLJS.Gc(function(){return oT},$CLJS.od(sja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,
$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tQ,"metabase/mbql/schema.cljc",56,$CLJS.Wx,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wx,$CLJS.sy,$CLJS.U($CLJS.Qj,dR)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:tQ}(),oT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof BT?new $CLJS.Gc(function(){return BT},$CLJS.od(iia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),nP,"metabase/mbql/schema.cljc",55,$CLJS.nG,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nG,$CLJS.HE,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:nP}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof CT?new $CLJS.Gc(function(){return CT},$CLJS.od(cia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),
$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bN,"metabase/mbql/schema.cljc",54,$CLJS.LF,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,$CLJS.HE,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(CT)?
CT.H:null])):null));return $CLJS.n(a)?a:bN}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof DT?new $CLJS.Gc(function(){return DT},$CLJS.od(ria,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dP,"metabase/mbql/schema.cljc",55,$CLJS.uG,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,$CLJS.HE,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:dP}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&
"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.od($ja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GP,"metabase/mbql/schema.cljc",53,$CLJS.QF,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.QF,$CLJS.HE,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:GP}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof FT?new $CLJS.Gc(function(){return FT},$CLJS.od(Nja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,
mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[YR,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sR,"metabase/mbql/schema.cljc",69,$CLJS.NF,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.NF,$CLJS.HE,$CLJS.U($CLJS.Qj,ZQ),AR,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:sR}(),FT],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof GT?new $CLJS.Gc(function(){return GT},$CLJS.od(Kia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[YR,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IO,"metabase/mbql/schema.cljc",68,$CLJS.cG,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,$CLJS.HE,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:IO}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof HT?new $CLJS.Gc(function(){return HT},$CLJS.od(Oja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),
$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[YR,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fP,"metabase/mbql/schema.cljc",67,$CLJS.HF,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HF,$CLJS.HE,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(HT)?
HT.H:null])):null));return $CLJS.n(a)?a:fP}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof IT?new $CLJS.Gc(function(){return IT},$CLJS.od(Wha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[YR,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cS,"metabase/mbql/schema.cljc",67,$CLJS.MG,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MG,$CLJS.HE,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:cS}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&
"undefined"!==typeof yU?new $CLJS.Gc(function(){return yU},$CLJS.od(PM,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xP,"metabase/mbql/schema.cljc",61,$CLJS.ZF,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.ZF,$CLJS.ix,$CLJS.U($CLJS.Qj,gO),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(pR,QP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(yU)?yU.H:null])):null));return $CLJS.n(a)?a:xP}(),yU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.od(Zha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,
$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dJ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HP,"metabase/mbql/schema.cljc",65,$CLJS.dJ,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dJ,lia,$CLJS.U($CLJS.Qj,nS),mia,$CLJS.U($CLJS.Qj,nS),$CLJS.ti,$CLJS.U($CLJS.Qj,Sia)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:HP}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof KT?new $CLJS.Gc(function(){return KT},$CLJS.od(wja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(XQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$P,"metabase/mbql/schema.cljc",71,$CLJS.sJ,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.sJ,$CLJS.hJ,$CLJS.U($CLJS.Qj,nS),$CLJS.ti,$CLJS.U($CLJS.Qj,Tja),$CLJS.zi,$CLJS.U($CLJS.Qj,$CLJS.U(pR,vS))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:$P}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.od(Dia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yN,"metabase/mbql/schema.cljc",71,$CLJS.$I,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$I,$CLJS.JP,$CLJS.U($CLJS.Qj,nS)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:yN}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof MT?new $CLJS.Gc(function(){return MT},$CLJS.od(sia,new $CLJS.h(null,1,[$CLJS.bk,!0],
null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",74,$CLJS.wJ,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wJ,$CLJS.JP,$CLJS.U($CLJS.Qj,nS)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(MT)?MT.H:null])):null));return $CLJS.n(a)?a:YP}(),MT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.od(Zia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),
$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vQ,"metabase/mbql/schema.cljc",72,$CLJS.TI,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TI,$CLJS.JP,$CLJS.U($CLJS.Qj,nS)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:vQ}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==
typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof OT?new $CLJS.Gc(function(){return OT},$CLJS.od(Sja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uQ,"metabase/mbql/schema.cljc",
71,$CLJS.mJ,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mJ,$CLJS.JP,$CLJS.U($CLJS.Qj,nS),$CLJS.zi,$CLJS.U($CLJS.Qj,$CLJS.U(pR,vS))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(OT)?OT.H:null])):null));return $CLJS.n(a)?a:uQ}(),OT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof PT?new $CLJS.Gc(function(){return PT},$CLJS.od(Nha,new $CLJS.h(null,1,[$CLJS.bk,
!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",70,$CLJS.lJ,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lJ,$CLJS.JP,$CLJS.U($CLJS.Qj,nS)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(PT)?PT.H:null])):null));return $CLJS.n(a)?a:nQ}(),PT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof QT?new $CLJS.Gc(function(){return QT},$CLJS.od(Tia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),
$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dQ,"metabase/mbql/schema.cljc",78,$CLJS.qJ,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qJ,$CLJS.JP,$CLJS.U($CLJS.Qj,nS)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(QT)?QT.H:null])):null));return $CLJS.n(a)?a:dQ}(),QT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==
typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},$CLJS.od(Ria,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FM,"metabase/mbql/schema.cljc",
71,$CLJS.tJ,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tJ,$CLJS.hJ,$CLJS.U($CLJS.Qj,nS)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(RT)?RT.H:null])):null));return $CLJS.n(a)?a:FM}(),RT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof ST?new $CLJS.Gc(function(){return ST},$CLJS.od(Aja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,
$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gS,"metabase/mbql/schema.cljc",73,$CLJS.pJ,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pJ,$CLJS.hJ,$CLJS.U($CLJS.Qj,nS)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(ST)?ST.H:null])):null));
return $CLJS.n(a)?a:gS}(),ST],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof TT?new $CLJS.Gc(function(){return TT},$CLJS.od(Oia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,
[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zP,"metabase/mbql/schema.cljc",73,$CLJS.ZI,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZI,$CLJS.hJ,$CLJS.U($CLJS.Qj,nS)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(TT)?TT.H:null])):null));return $CLJS.n(a)?a:zP}(),TT],null)])));
$CLJS.X(UM,lM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof nT?new $CLJS.Gc(function(){return nT},$CLJS.od(qja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DQ,"metabase/mbql/schema.cljc",59,$CLJS.IF,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.IF,$CLJS.sy,$CLJS.U($CLJS.Qj,dR),$CLJS.Fv,$CLJS.U($CLJS.Qj,oia),$CLJS.Wx,$CLJS.U($CLJS.Qj,$CLJS.U(pR,ZQ))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:DQ}(),nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof pT?new $CLJS.Gc(function(){return pT},
$CLJS.od(Qja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$Q,"metabase/mbql/schema.cljc",54,$CLJS.FG,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FG,$CLJS.sy,$CLJS.U($CLJS.Qj,dR)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:$Q}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof rT?new $CLJS.Gc(function(){return rT},$CLJS.od(Uia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,
1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JQ,"metabase/mbql/schema.cljc",55,$CLJS.HG,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HG,$CLJS.sy,$CLJS.U($CLJS.Qj,dR)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:JQ}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&
"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},$CLJS.od(rja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LP,"metabase/mbql/schema.cljc",
55,$CLJS.OG,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OG,$CLJS.sy,$CLJS.U($CLJS.Qj,dR)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:LP}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.od(Iha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,
$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LR,"metabase/mbql/schema.cljc",57,$CLJS.NG,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.NG,$CLJS.sy,$CLJS.U($CLJS.Qj,dR),Uha,$CLJS.U($CLJS.Qj,$CLJS.Zj),$CLJS.rA,$CLJS.U($CLJS.Qj,$CLJS.Zj)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:LR}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof tT?new $CLJS.Gc(function(){return tT},$CLJS.od(uja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qS,"metabase/mbql/schema.cljc",55,$CLJS.Qt,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qt,$CLJS.sy,$CLJS.U($CLJS.Qj,dR)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:qS}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==
typeof pM&&"undefined"!==typeof sT?new $CLJS.Gc(function(){return sT},$CLJS.od(Rja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mR,"metabase/mbql/schema.cljc",55,$CLJS.Kt,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Kt,$CLJS.sy,$CLJS.U($CLJS.Qj,dR)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:mR}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},$CLJS.od(ija,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,
$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zO,"metabase/mbql/schema.cljc",56,$CLJS.PF,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.PF,JR,$CLJS.U($CLJS.Qj,dR),IR,$CLJS.U($CLJS.Qj,dR),RN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Ni,dR))],null),$CLJS.Kc,"Schema for a valid concat clause.",
$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:zO}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof wT?new $CLJS.Gc(function(){return wT},$CLJS.od(pja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.gQ,"null",$CLJS.Oda,"null"],
null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wN,"metabase/mbql/schema.cljc",74,$CLJS.rG,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.rG,$CLJS.sy,$CLJS.U($CLJS.Qj,dR),$CLJS.qA,$CLJS.U($CLJS.Qj,$CLJS.Zj)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:wN}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==
typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof mT?new $CLJS.Gc(function(){return mT},$CLJS.od(MQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gQ,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
aR,"metabase/mbql/schema.cljc",58,$CLJS.sG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.sG,JR,$CLJS.U($CLJS.Qj,EM),IR,$CLJS.U($CLJS.Qj,EM),RN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Ni,EM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:aR}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof yU?new $CLJS.Gc(function(){return yU},
$CLJS.od(PM,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xP,"metabase/mbql/schema.cljc",61,$CLJS.ZF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ZF,$CLJS.ix,$CLJS.U($CLJS.Qj,gO),
$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(pR,QP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(yU)?yU.H:null])):null));return $CLJS.n(a)?a:xP}(),yU],null)])));
$CLJS.zU=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.xt,":field or :expression reference or expression",$CLJS.Pi,function(a){return $CLJS.n(jM(aT,a))?oS:$CLJS.n(jM(YS,a))?$CLJS.Zj:$CLJS.n(jM(bT,a))?$CLJS.Os:$CLJS.n(jM(dT,a))?$CLJS.hJ:$CLJS.n(jM($CLJS.ZF,a))?$CLJS.ZF:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[oS,eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,$CLJS.gT],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZF,yU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,$CLJS.VS],null)],null);$CLJS.X(UR,kM($CLJS.Yx,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,$CLJS.VS],null)])));var AU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UR],null);$CLJS.X(IP,kM($CLJS.qG,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,$CLJS.VS],null)])));var BU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IP],null);$CLJS.X(SM,kM($CLJS.jG,$CLJS.H(["field-or-expression",$CLJS.zU])));
var CU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SM],null);$CLJS.X(MR,kM($CLJS.LG,$CLJS.H(["field-or-expression",$CLJS.zU])));var DU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MR],null);$CLJS.X(rO,kM($CLJS.VF,$CLJS.H(["field-or-expression",$CLJS.zU])));var EU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rO],null);$CLJS.X(bR,kM($CLJS.CG,$CLJS.H(["field-or-expression",$CLJS.zU])));var FU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bR],null);$CLJS.X(mO,kM($CLJS.$n,$CLJS.H(["field-or-expression",$CLJS.zU])));
var GU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mO],null);$CLJS.X(ZN,kM($CLJS.jk,$CLJS.H(["field-or-expression",$CLJS.zU])));var HU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZN],null);$CLJS.X(TN,kM($CLJS.JG,$CLJS.H(["field-or-expression",$CLJS.zU,"pred",$CLJS.ZT])));var IU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TN],null);$CLJS.X(jO,kM($CLJS.yG,$CLJS.H(["pred",$CLJS.ZT])));var JU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jO],null);$CLJS.X(TQ,kM($CLJS.wG,$CLJS.H(["pred",$CLJS.ZT])));
var KU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TQ],null);$CLJS.X(pN,kM($CLJS.EG,$CLJS.H(["field-or-expression",$CLJS.zU])));var LU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pN],null);$CLJS.X(rM,kM($CLJS.WF,$CLJS.H(["field-or-expression",$CLJS.zU])));var MU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rM],null);$CLJS.X(vO,kM($CLJS.DG,$CLJS.H(["field-or-expression",$CLJS.zU])));var NU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vO],null);$CLJS.X(iP,kM($CLJS.aG,$CLJS.H(["field-or-expression",$CLJS.zU,"percentile",iT])));
var OU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iP],null);$CLJS.X(WQ,kM($CLJS.kG,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UK],null),wS],null)])));var PU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WQ],null);
$CLJS.X(SQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.xt,"unnamed aggregation clause or numeric expression",$CLJS.Pi,function(a){return $CLJS.n(jM(aT,a))?lP:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[lP,eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,lM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof CU?new $CLJS.Gc(function(){return CU},$CLJS.od(oja,
new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uP,"metabase/mbql/schema.cljc",60,$CLJS.jG,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,nR,$CLJS.U($CLJS.Qj,fR)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(CU)?CU.H:null])):null));return $CLJS.n(a)?a:uP}(),CU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof DU?new $CLJS.Gc(function(){return DU},$CLJS.od(zia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(XQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zR,"metabase/mbql/schema.cljc",64,$CLJS.LG,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LG,nR,$CLJS.U($CLJS.Qj,fR)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(DU)?DU.H:null])):null));return $CLJS.n(a)?a:zR}(),DU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==
typeof pM&&"undefined"!==typeof EU?new $CLJS.Gc(function(){return EU},$CLJS.od(qia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yP,"metabase/mbql/schema.cljc",65,$CLJS.VF,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VF,nR,$CLJS.U($CLJS.Qj,fR)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(EU)?EU.H:null])):null));return $CLJS.n(a)?a:yP}(),EU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof LU?new $CLJS.Gc(function(){return LU},$CLJS.od(Kja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,
$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.zG,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MM,"metabase/mbql/schema.cljc",76,$CLJS.EG,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EG,nR,$CLJS.U($CLJS.Qj,fR)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(LU)?LU.H:null])):null));return $CLJS.n(a)?a:MM}(),LU],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof FU?new $CLJS.Gc(function(){return FU},$CLJS.od(dja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hN,"metabase/mbql/schema.cljc",60,$CLJS.CG,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CG,nR,$CLJS.U($CLJS.Qj,fR)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(FU)?FU.H:null])):null));return $CLJS.n(a)?a:hN}(),FU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof GU?new $CLJS.Gc(function(){return GU},$CLJS.od(Rha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,
$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.dt,"metabase/mbql/schema.cljc",60,$CLJS.$n,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$n,nR,$CLJS.U($CLJS.Qj,fR)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(GU)?GU.H:
null])):null));return $CLJS.n(a)?a:$CLJS.dt}(),GU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof HU?new $CLJS.Gc(function(){return HU},$CLJS.od(Mha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ht,"metabase/mbql/schema.cljc",60,$CLJS.jk,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jk,nR,$CLJS.U($CLJS.Qj,fR)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:$CLJS.ht}(),HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&
"undefined"!==typeof PU?new $CLJS.Gc(function(){return PU},$CLJS.od(nia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yO,"metabase/mbql/schema.cljc",18,$CLJS.kG,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kG,Aia,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.ys,Eia,cN],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(PU)?PU.H:null])):null));return $CLJS.n(a)?a:yO}(),PU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof KU?new $CLJS.Gc(function(){return KU},$CLJS.od(Mia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,
$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qO,"metabase/mbql/schema.cljc",62,$CLJS.wG,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,$CLJS.so,$CLJS.U($CLJS.Qj,gP)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:qO}(),KU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof JU?new $CLJS.Gc(function(){return JU},$CLJS.od(Sha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dN,"metabase/mbql/schema.cljc",68,$CLJS.yG,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yG,$CLJS.so,$CLJS.U($CLJS.Qj,gP)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(JU)?JU.H:null])):null));return $CLJS.n(a)?a:dN}(),JU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof IU?new $CLJS.Gc(function(){return IU},$CLJS.od(Yja,new $CLJS.h(null,1,[$CLJS.bk,!0],
null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vP,"metabase/mbql/schema.cljc",66,$CLJS.JG,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.JG,nR,$CLJS.U($CLJS.Qj,fR),$CLJS.so,$CLJS.U($CLJS.Qj,gP)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:vP}(),IU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof yU?new $CLJS.Gc(function(){return yU},$CLJS.od(PM,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(XQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xP,"metabase/mbql/schema.cljc",61,$CLJS.ZF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ZF,$CLJS.ix,$CLJS.U($CLJS.Qj,gO),$CLJS.gj,$CLJS.U($CLJS.Qj,$CLJS.U(pR,QP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(yU)?yU.H:null])):null));return $CLJS.n(a)?a:xP}(),yU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&
"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof NU?new $CLJS.Gc(function(){return NU},$CLJS.od(Dja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lG,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xR,"metabase/mbql/schema.cljc",
68,$CLJS.DG,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DG,nR,$CLJS.U($CLJS.Qj,fR)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(NU)?NU.H:null])):null));return $CLJS.n(a)?a:xR}(),NU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof OU?new $CLJS.Gc(function(){return OU},$CLJS.od(Yia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,
qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lG,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VQ,"metabase/mbql/schema.cljc",72,$CLJS.aG,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aG,nR,$CLJS.U($CLJS.Qj,fR),$CLJS.aG,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(OU)?OU.H:null])):
null));return $CLJS.n(a)?a:VQ}(),OU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof MU?new $CLJS.Gc(function(){return MU},$CLJS.od(bia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HM,"metabase/mbql/schema.cljc",77,$CLJS.WF,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WF,nR,$CLJS.U($CLJS.Qj,fR)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(MU)?MU.H:null])):null));return $CLJS.n(a)?a:HM}(),MU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof BU?new $CLJS.Gc(function(){return BU},$CLJS.od(Oha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,
$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hP,"metabase/mbql/schema.cljc",74,$CLJS.qG,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,$CLJS.pG,$CLJS.U($CLJS.Qj,$CLJS.U(pR,NN))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(BU)?BU.H:null])):null));return $CLJS.n(a)?a:hP}(),BU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof AU?new $CLJS.Gc(function(){return AU},$CLJS.od(pia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([oP,$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,rR,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),
$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.yi,"metabase/mbql/schema.cljc",70,$CLJS.Yx,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yx,$CLJS.pG,$CLJS.U($CLJS.Qj,$CLJS.U(pR,NN))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(AU)?AU.H:null])):null));return $CLJS.n(a)?a:$CLJS.yi}(),AU],null)]))],null)],null));
$CLJS.X(kQ,kM($CLJS.CM,$CLJS.H(["aggregation",SQ,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.xt,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),wS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),wS],null)],null)])));
$CLJS.X(oR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.xt,"aggregation clause or numeric expression",$CLJS.Pi,function(a){return $CLJS.n(jM($CLJS.CM,a))?$CLJS.CM:fO}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[fO,SQ],null)],null));$CLJS.X(LM,kM($CLJS.mG,$CLJS.H(["field",XS])));var QU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LM],null);$CLJS.X(kP,kM($CLJS.fG,$CLJS.H(["field",XS])));
RU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kP],null);
pka=lM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof QU?new $CLJS.Gc(function(){return QU},$CLJS.od(Hja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
RO,"metabase/mbql/schema.cljc",15,$CLJS.mG,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,$CLJS.pG,$CLJS.U($CLJS.Qj,HR)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(QU)?QU.H:null])):null));return $CLJS.n(a)?a:RO}(),QU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof RU?new $CLJS.Gc(function(){return RU},$CLJS.od(yia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,
$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NQ,"metabase/mbql/schema.cljc",16,$CLJS.fG,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fG,$CLJS.pG,$CLJS.U($CLJS.Qj,HR)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(RU)?RU.H:null])):null));return $CLJS.n(a)?a:NQ}(),RU],null)]));
SU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.nk,$CLJS.OM,$CLJS.DN,$CLJS.JF,$CLJS.Zt,$CLJS.CB,$CLJS.JP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uE,wS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),wS],null)],null);
qka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,SU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.OM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AM,wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aQ,zS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),zS],null)],null)],null);
rka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,SU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.DN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kR,zS],null)],null)],null);
TU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,SU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.mi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Os],null)],null)],null);ska=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yR],null);
tka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,TU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.JF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JF,US],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,$CLJS.Yi,$CLJS.mi],null)],null)],null)],null);
$CLJS.UU=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.JP,null,$CLJS.Zt,null,$CLJS.Os,null,$CLJS.CB,null],null),null);uka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.nk],null),$CLJS.UU);
$CLJS.X(GQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Pi,$CLJS.nj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JF,tka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OM,qka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DN,rka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ap,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,TU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,uka],null)],null)],null)],null)],null));
var VU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,wS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,wS],null)],null)],null),vka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,VU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MF,$CLJS.mi],null)],null)],null),WU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oQ],null),XU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Pi,function(a){return $CLJS.n($CLJS.nE($CLJS.xd,
$CLJS.rQ)(a))?$CLJS.rQ:$CLJS.tE}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,VU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rQ,$CLJS.mi],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tE,WU],null)],null),YU=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mD,xS],null),new $CLJS.P(null,2,5,$CLJS.Q,[fja,wS],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.LO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,yS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$N,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.ik],null)],null)],null),ZU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,hka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.xt,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),wka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.nk],null),
new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.oF,null,$CLJS.YE,null,$CLJS.hF,null,$CLJS.lF,null],null),null)),$U=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wR],null);
$CLJS.X(pO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),ZU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),XU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZR,$CLJS.ZT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),wka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.xt,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nk,$CLJS.Dy,$CLJS.lA],null),$U],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cF,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),wS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gL,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,BS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZO,new $CLJS.h(null,
1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,YU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.nE($CLJS.GA($CLJS.mP,$CLJS.QQ),$CLJS.Te($CLJS.nE($CLJS.mP,$CLJS.QQ)))],null)],null));var xka=$CLJS.Q,aV;var bV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pO],null)],null);
if($CLJS.wd(bV)&&$CLJS.E.g($CLJS.A(bV),$CLJS.Aj)){var cV=$CLJS.y(bV);$CLJS.A(cV);var dV=$CLJS.B(cV),yka=$CLJS.xd($CLJS.A(dV))?dV:$CLJS.ee(null,dV),eV=$CLJS.y(yka),zka=$CLJS.A(eV),Aka=$CLJS.B(eV);aV=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.R.j(zka,$CLJS.$n,1)],null),Aka)}else aV=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,bV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(rN,new $CLJS.P(null,3,5,xka,[$CLJS.Fs,aV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"All join aliases must be unique."],null),function(a){return $CLJS.WL($CLJS.lf($CLJS.Wa,$CLJS.hf.g($CLJS.cF,a)))}],null)],null));var Bka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rN],null);
$CLJS.X(wR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.xt,"Distinct, non-empty sequence of Field clauses"],null),mM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.$n,1],null),$CLJS.VS],null))],null));
$CLJS.X(oQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),XU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),ZU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.$n,1],null),$CLJS.hT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dG,new $CLJS.h(null,1,
[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.$n,1],null),$CLJS.VS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,wS,$CLJS.zU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$U],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UF,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.ZT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tS,new $CLJS.h(null,
1,[$CLJS.Ss,!0],null),AS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WR,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),mM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.$n,1],null),pka],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[sQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[sQ,zS],null),new $CLJS.P(null,2,5,$CLJS.Q,[gia,zS],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),Bka],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,YU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.fm(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QQ,$CLJS.mP],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,
new $CLJS.h(null,1,[$CLJS.xt,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.dG);b=$CLJS.J.g(b,$CLJS.ZE);return $CLJS.td($CLJS.nu.g($CLJS.fh(a),$CLJS.fh(b)))}],null)],null));
$CLJS.fV=$CLJS.Ig([$CLJS.IG,$CLJS.JP,lO,XN,$O,kS,GO,$CLJS.jS,$CLJS.Zt,FO,VM,cR,$R,$CLJS.kN,VR,iO,$CLJS.eP,DO,$CLJS.$i,$CLJS.CO,DM,$CLJS.HN,$CLJS.BO,EP,$CLJS.Os,$CLJS.CB,xO],[new $CLJS.h(null,1,[QO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.IG,null,$CLJS.JP,null,$CLJS.Zt,null,$CLJS.Os,null,$CLJS.CB,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.JP,QO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.IG,null,$CLJS.JP,null,$CLJS.jS,null,$CLJS.eP,null,$CLJS.$i,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.nj,$CLJS.Zj,$CLJS.CE,lR,QO,new $CLJS.ah(null,new $CLJS.h(null,1,[lO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,oS,$CLJS.CE,lR,QO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.IG,null,XN,null,$CLJS.Zt,null,$CLJS.$i,null,DM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,$CLJS.Zj,$CLJS.CE,ZM,QO,new $CLJS.ah(null,new $CLJS.h(null,1,[$O,null],null),null)],null),new $CLJS.h(null,1,[QO,new $CLJS.ah(null,new $CLJS.h(null,1,[kS,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,
oS,$CLJS.CE,xja,QO,new $CLJS.ah(null,new $CLJS.h(null,1,[GO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.JP,QO,new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.jS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,oS,QO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.IG,null,XN,null,$CLJS.Zt,null,$CLJS.$i,null,DM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,oS,$CLJS.CE,ZM,QO,new $CLJS.ah(null,new $CLJS.h(null,1,[FO,null],null),null)],null),new $CLJS.h(null,1,[QO,new $CLJS.ah(null,
new $CLJS.h(null,1,[VM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,$CLJS.Zj,$CLJS.CE,lR,QO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.IG,null,kS,null,VM,null,cR,null,DO,null,$CLJS.$i,null,DM,null,$CLJS.CB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,$CLJS.Zj,$CLJS.CE,ZM,QO,new $CLJS.ah(null,new $CLJS.h(null,1,[$R,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.JP,QO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.jS,null,$CLJS.kN,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.nj,$CLJS.Zj,$CLJS.CE,ZM,QO,new $CLJS.ah(null,new $CLJS.h(null,1,[VR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,$CLJS.Zj,$CLJS.CE,ZM,QO,new $CLJS.ah(null,new $CLJS.h(null,1,[iO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.JP,QO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.IG,null,$CLJS.JP,null,$CLJS.jS,null,$CLJS.eP,null,$CLJS.$i,null],null),null)],null),new $CLJS.h(null,1,[QO,new $CLJS.ah(null,new $CLJS.h(null,1,[DO,null],null),null)],null),new $CLJS.h(null,1,[QO,
new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$i,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.JP,QO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.jS,null,$CLJS.CO,null],null),null)],null),new $CLJS.h(null,1,[QO,new $CLJS.ah(null,new $CLJS.h(null,1,[DM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.JP,QO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.jS,null,$CLJS.HN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.JP,QO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.jS,
null,$CLJS.BO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,oS,$CLJS.CE,lR,QO,new $CLJS.ah(null,new $CLJS.h(null,1,[EP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.Os,QO,new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.IG,null,$CLJS.$i,null,$CLJS.Os,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.Zj,QO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.IG,null,kS,null,VM,null,cR,null,DO,null,$CLJS.$i,null,DM,null,$CLJS.CB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,
oS,$CLJS.CE,ZM,QO,new $CLJS.ah(null,new $CLJS.h(null,1,[xO,null],null),null)],null)]);$CLJS.X(yR,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.xt,"valid parameter type"],null)],null),$CLJS.Xg($CLJS.fV)));$CLJS.X(gN,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.xt,"valid template tag widget type"],null),$CLJS.lA],null),$CLJS.Xg($CLJS.fV)));
$CLJS.X(LQ,kM(Yha,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,wS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,wS],null)],null)],null)])));var gV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LQ],null);$CLJS.X(GM,kM($CLJS.JF,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.VS,gV],null)])));var hV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GM],null);$CLJS.X(BN,kM(AO,$CLJS.H(["target",gV])));
var iV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BN],null),Cka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.VS,lM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof hV?new $CLJS.Gc(function(){return hV},$CLJS.od(Wia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wQ,"metabase/mbql/schema.cljc",21,$CLJS.JF,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JF,$CLJS.zJ,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,NN,PO],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(hV)?hV.H:null])):null));return $CLJS.n(a)?a:wQ}(),hV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=qP.h($CLJS.pd("undefined"!==typeof $CLJS.nM&&
"undefined"!==typeof oM&&"undefined"!==typeof pM&&"undefined"!==typeof iV?new $CLJS.Gc(function(){return iV},$CLJS.od(lja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.rk,$CLJS.wi,qP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,mN,$CLJS.vi,$CLJS.kk,$CLJS.qk],[$CLJS.od(XQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pS,"metabase/mbql/schema.cljc",20,AO,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[AO,
$CLJS.zJ,$CLJS.U($CLJS.Qj,PO)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(iV)?iV.H:null])):null));return $CLJS.n(a)?a:pS}(),iV],null)]))],null),gM=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nN],null);
$CLJS.X(nN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.nE($CLJS.GA($CLJS.rQ,$CLJS.MF),$CLJS.Te($CLJS.nE($CLJS.rQ,$CLJS.MF)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.rQ);var d=$CLJS.J.g(c,$CLJS.MF);c=$CLJS.J.g(c,$CLJS.nj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.xt,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kL],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.eL],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nk,$CLJS.MF,$CLJS.rQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),vka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MF,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),WU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,ska],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),wS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zJ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),Cka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.mi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),wS],null),new $CLJS.P(null,3,5,$CLJS.Q,[dia,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),
wS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.mi],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iL,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[Wja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),JS],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Via,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[wP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),AS],null),new $CLJS.P(null,3,5,$CLJS.Q,[fN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),AS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,wP);b=$CLJS.J.g(b,fN);return $CLJS.Va($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Lia,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[Gja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,[$ha,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jha,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,[fia,new $CLJS.h(null,1,[$CLJS.Ss,
!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,[yja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.Os],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Lha,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.Os],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Mja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.Os],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ny,new $CLJS.h(null,
1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.nk,kja,hja,$CLJS.tO,jia,fka,mja,Bia,Xia,tia,aia,xia,vja,Jia,Vha,kia,eka,bka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Bja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,zS],null)],null),new $CLJS.P(null,3,5,
$CLJS.Q,[Gia,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,zS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kR,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,gka],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Xja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,wS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Iia,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,zS],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[Vja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,$CLJS.mi,$CLJS.mi],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Cia,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,zS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Ds,$CLJS.mi,$CLJS.mi],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SK,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.mi],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Te($CLJS.ZO)],null)],null));var jV=$CLJS.fM(gM);
(function(){var a=Eha();return function(b){if($CLJS.n(jV.h?jV.h(b):jV.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.cM(b);throw $CLJS.ii($CLJS.pE("Invalid query: {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Jy,c,Qia,b],null));}})();