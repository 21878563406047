var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var k7,m7,n7,gra,hra,ira,q7,r7,t7,u7,jra,v7,kra,lra;k7=function(a){var b=new $CLJS.h(null,3,[$CLJS.GE,$CLJS.p.h($CLJS.QE()),$CLJS.yB,$CLJS.yB.h(a),$CLJS.Gi,$CLJS.GA($CLJS.Gi,$CLJS.yB)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vB,b,$CLJS.GA($CLJS.yE,$CLJS.T)(a)],null)};$CLJS.l7=function(a){return $CLJS.z5.h(a)};m7=function(a){return $CLJS.R.j(a,$CLJS.eu,$CLJS.Q4)};
n7=function(a,b){return $CLJS.E.g($CLJS.tE.h(a),$CLJS.fm(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KE,$CLJS.sE,$CLJS.JE],null)))};gra=function(a){return $CLJS.w1(a,new $CLJS.h(null,1,[$CLJS.KE,$CLJS.zh],null))};
hra=function(a,b){var c=$CLJS.Re(function(e){return $CLJS.CH(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SZ,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.QA($CLJS.Ly);if($CLJS.n($CLJS.PA("metabase.lib.field",c))){var d=$CLJS.pE("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([a])),$CLJS.Mh.l($CLJS.H([$CLJS.Tk.g($CLJS.SZ,b)]))]));d instanceof Error?$CLJS.OA("metabase.lib.field",c,$CLJS.Hw(),d):$CLJS.OA("metabase.lib.field",
c,$CLJS.Hw.l($CLJS.H([d])),null)}return null};
ira=function(a,b,c){if($CLJS.n(o7))return null;var d=o7;o7=!0;try{var e=$CLJS.h2(a,b),f=$CLJS.n(e)?$CLJS.NW(a,e):$CLJS.NW(a,b),k=function(){var m=$CLJS.p7.h(f);if($CLJS.n(m))return m;m=$CLJS.HA(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vV,$CLJS.KW],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.WV.h(f);if($CLJS.n(u))return u;u=$CLJS.mP.h(f);if($CLJS.n(u))return u;u=$CLJS.gQ.h(f);return $CLJS.n(u)?u:$CLJS.ZE.h(f)}())?$CLJS.S0.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.QA($CLJS.Ly);if($CLJS.n($CLJS.PA("metabase.lib.field",
m))){var t=$CLJS.pE("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.OA("metabase.lib.field",m,$CLJS.Hw(),t):$CLJS.OA("metabase.lib.field",m,$CLJS.Hw.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?hra(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.I3($CLJS.Jk.l(l,$CLJS.$i,$CLJS.H([$CLJS.hL,$CLJS.XW,$CLJS.RW])),null),$CLJS.T,function(){var m=$CLJS.SZ.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.VK,$CLJS.GZ):l:null}finally{o7=d}};
q7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.BP);var f=$CLJS.I(c,2,null);c=$CLJS.il.l($CLJS.H([function(){var k=$CLJS.yB.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.yB,k],null):null}(),function(){var k=$CLJS.GA($CLJS.Gi,$CLJS.yB)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.Gi,k],null):null}(),function(){var k=$CLJS.CR.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.XW,k],null):null}(),function(){var k=$CLJS.vG.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.RW,k],null):null}(),$CLJS.Fd(f)?function(){var k=$CLJS.m2(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.eu,$CLJS.TK,$CLJS.T,f],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.eu,$CLJS.TK,$CLJS.T,f],null):function(){var k=ira(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.eu,$CLJS.TK,$CLJS.T,f],null)}()]));return $CLJS.n(d)?$CLJS.I3(c,d):c};r7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.RW);return $CLJS.n($CLJS.n(b)?$CLJS.Hd($CLJS.zH,b):b)?$CLJS.xj:$CLJS.GA($CLJS.Gi,$CLJS.yB)(a)};
$CLJS.s7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.GE),l=$CLJS.J.g(f,$CLJS.yB),m=$CLJS.J.g(f,$CLJS.CR),t=$CLJS.J.g(f,$CLJS.Gi);e=$CLJS.J.g(f,$CLJS.BP);var u=$CLJS.J.g(f,$CLJS.MN),v=$CLJS.J.g(f,$CLJS.vG),x=$CLJS.il.l;k=new $CLJS.h(null,2,[$CLJS.eu,$CLJS.TK,$CLJS.k1,k],null);f=$CLJS.uE.h(f);a=$CLJS.n(f)?f:$CLJS.h0.j(a,b,d);c=x.call($CLJS.il,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.uE,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.Gi,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.yB,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.RW,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.XW,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.gL,u):m;return $CLJS.n(e)?$CLJS.I3(u,e):u};t7=function(a,b,c){return $CLJS.n($CLJS.Re(function(d){return $CLJS.E.g($CLJS.ti.h(d),c)},a))?$CLJS.Tk.g(function(d){var e=$CLJS.Hd(d,b)?$CLJS.Jk.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.ti.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
u7=function(a){var b=$CLJS.n($CLJS.R1.h(a))?null:function(){var d=$CLJS.VK.h(a),e=new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.VZ,null,$CLJS.a_,null,$CLJS.GZ,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.il.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.GE,$CLJS.p.h($CLJS.QE()),$CLJS.yB,$CLJS.yB.h(a),$CLJS.Gi,r7(a)],null),function(){var d=$CLJS.K1(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.BP,d],null):null}(),function(){var d=$CLJS.RW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.vG,d],
null):null}(),function(){var d=$CLJS.VW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.VW,d],null):null}(),function(){var d=$CLJS.XW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.CR,d],null):null}(),function(){var d=$CLJS.gL.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.MN,d],null):null}()]));b=($CLJS.n(b)?$CLJS.GA($CLJS.SZ,$CLJS.T):$CLJS.GA($CLJS.$i,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,c,b],null)};
jra=function(a,b){return $CLJS.lf(function(c){return $CLJS.E.g($CLJS.VK.h(c),$CLJS.MZ)},$CLJS.S0.v(a,b,$CLJS.NW(a,b),new $CLJS.h(null,3,[$CLJS.A0,!1,$CLJS.G0,!0,$CLJS.w0,!1],null)))};v7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.p7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);kra=new $CLJS.M(null,"earliest","earliest",-1928154382);lra=new $CLJS.M(null,"latest","latest",24323665);var ora;$CLJS.x1.m(null,$CLJS.pG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zh.h(b),$CLJS.w1(c,new $CLJS.h(null,2,[$CLJS.vG,$CLJS.zh,$CLJS.CR,gra],null)),a],null)});var o7=!1,w7=function w7(a,b){var d=$CLJS.D1(a,v7.h(b));a=$CLJS.n(v7.h(d))?w7.g?w7.g(a,d):w7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T);return $CLJS.Uk.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.L0.m(null,$CLJS.TK,function(a,b,c){return r7(c)});$CLJS.L0.m(null,$CLJS.pG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.vG);$CLJS.I(c,2,null);c=q7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.RW,d):c;return $CLJS.M0.j(a,b,c)});$CLJS.N0.m(null,$CLJS.TK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.N0.m(null,$CLJS.pG,function(a,b,c){var d=q7(a,b,c);b=$CLJS.s7(a,b,d,c);return $CLJS.n(v7.h(b))?w7(a,b):b});
$CLJS.H0.m(null,$CLJS.TK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.uE),f=$CLJS.J.g(c,$CLJS.T),k=$CLJS.J.g(c,$CLJS.ti),l=$CLJS.J.g(c,$CLJS.XW),m=$CLJS.J.g(c,$CLJS.d_),t=$CLJS.J.g(c,$CLJS.gL),u=$CLJS.J.g(c,$CLJS.hL);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.I1.g($CLJS.DB,f):$CLJS.p.h(f);$CLJS.E.g(d,$CLJS.i0)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.D1(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.KA($CLJS.uE.h($CLJS.k0.j(a,b,t)),$CLJS.ZW,"")):(u=$CLJS.J1(a,u),a=$CLJS.h0.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.K1(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.gB($CLJS.KA($CLJS.gh(k),"-"," ")),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.w5(l,c),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):m});
$CLJS.H0.m(null,$CLJS.pG,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.CR);var k=$CLJS.J.g(f,$CLJS.BP),l=$CLJS.J.g(f,$CLJS.vG);f=$CLJS.J.g(f,$CLJS.MN);$CLJS.I(c,2,null);c=q7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.d_,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.ti,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.XW,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.gL,f):e;return $CLJS.n(e)?$CLJS.h0.v(a,b,e,d):$CLJS.RE("[Unknown Field]")});
$CLJS.I0.m(null,$CLJS.TK,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.I0.m(null,$CLJS.pG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=q7(a,b,c);return $CLJS.n(c)?$CLJS.J0.j(a,b,c):"unknown_field"});
$CLJS.O0.m(null,$CLJS.TK,function(a,b,c){return $CLJS.il.l($CLJS.H([function(){var d=$CLJS.bi($CLJS.O0,$CLJS.di);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.E.g($CLJS.VK.h(c),$CLJS.VZ)?function(){var d=$CLJS.c_.h(c);return $CLJS.n(d)?(d=$CLJS.d0(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.WW,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.uE,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.b1.m(null,$CLJS.pG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.vG.h(b)});
$CLJS.b1.m(null,$CLJS.TK,function(a){return $CLJS.RW.h(a)});
$CLJS.a1.m(null,$CLJS.pG,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Hd($CLJS.zH,b),e=$CLJS.xC($CLJS.VW,$CLJS.Gi,$CLJS.yB)(c);c=$CLJS.R.l(c,$CLJS.vG,b,$CLJS.H([$CLJS.Gi,d?$CLJS.xj:e,$CLJS.VW,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,c,a],null)}b=$CLJS.VW.h(c);c=$CLJS.n(b)?$CLJS.Jk.g($CLJS.R.j(c,$CLJS.Gi,b),$CLJS.VW):c;c=$CLJS.Jk.g(c,$CLJS.vG);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,c,a],null)});
$CLJS.a1.m(null,$CLJS.TK,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.RW,b,$CLJS.H([$CLJS.VW,$CLJS.xC($CLJS.VW,$CLJS.Gi,$CLJS.yB)(a)])):$CLJS.Jk.l(a,$CLJS.RW,$CLJS.H([$CLJS.VW]))});$CLJS.d1.m(null,$CLJS.pG,function(a,b,c){return $CLJS.e1.j(a,b,q7(a,b,c))});
$CLJS.d1.m(null,$CLJS.TK,function(a,b,c){if($CLJS.Pk.g($CLJS.VK.h(c),$CLJS.MZ)){a=$CLJS.GA($CLJS.Gi,$CLJS.yB)(c);b=null==c?null:$CLJS.$N.h(c);if(null==b)var d=null;else try{var e=$CLJS.zD.h($CLJS.nj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,kra),l=$CLJS.J.g(f,lra),m=$CLJS.yna.l($CLJS.H([$CLJS.Z0.h(k),$CLJS.Z0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Wr.g?$CLJS.Wr.g(1,m):$CLJS.Wr.call(null,1,m))?$CLJS.Vi:$CLJS.n($CLJS.Wr.g?$CLJS.Wr.g(31,m):$CLJS.Wr.call(null,31,m))?$CLJS.fu:$CLJS.n($CLJS.Wr.g?
$CLJS.Wr.g(365,m):$CLJS.Wr.call(null,365,m))?$CLJS.pj:$CLJS.yj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.zC(a,$CLJS.zD)?$CLJS.Gna:$CLJS.zC(a,$CLJS.$C)?$CLJS.Fna:$CLJS.zC(a,$CLJS.ZD)?$CLJS.Ena:$CLJS.Cf;d=$CLJS.n(d)?t7(e,$CLJS.di,d):e;return $CLJS.n($CLJS.RW.h(c))?t7(d,$CLJS.p0,$CLJS.RW.h(c)):d}return $CLJS.Cf});
$CLJS.z5.m(null,$CLJS.pG,function(a){var b=null==a?null:$CLJS.NE(a);b=null==b?null:$CLJS.CR.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.eu,$CLJS.y5,$CLJS.H([$CLJS.x5,function(c,d){return q7(c,d,a)}]))});$CLJS.z5.m(null,$CLJS.TK,function(a){var b=null==a?null:$CLJS.XW.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.eu,$CLJS.y5,$CLJS.H([$CLJS.x5,$CLJS.Ue(a)]))});$CLJS.t5.m(null,$CLJS.pG,function(a,b){return $CLJS.OE(a,$CLJS.ME,$CLJS.H([$CLJS.CR,b]))});
$CLJS.t5.m(null,$CLJS.TK,function(a,b){return $CLJS.ME(a,$CLJS.XW,b)});$CLJS.A5.m(null,$CLJS.pG,function(a,b,c){return $CLJS.B5.j(a,b,q7(a,b,c))});
$CLJS.A5.m(null,$CLJS.TK,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.Gi);var d=$CLJS.J.g(b,$CLJS.$N),e=$CLJS.J.g(b,$CLJS.Vj);if($CLJS.Pk.g($CLJS.VK.h(b),$CLJS.MZ)){var f=function(){var m=null==a?null:$CLJS.B1($CLJS.a0(a));m=null==m?null:$CLJS.mA.h(m);return null==m?null:$CLJS.Hd(m,$CLJS.CR)}(),k=$CLJS.HA(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,$CLJS.Pj],null)),l=$CLJS.l7(b);return function u(t){return new $CLJS.ne(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.Ad(v)){var x=$CLJS.lc(v),
z=$CLJS.D(x),C=$CLJS.qe(z);return function(){for(var K=0;;)if(K<z){var S=$CLJS.kd(x,K),V=C,Z=S;S=n7(S,l)?$CLJS.R.j(Z,$CLJS.p0,!0):Z;V.add(S);K+=1}else return!0}()?$CLJS.te($CLJS.ve(C),u($CLJS.mc(v))):$CLJS.te($CLJS.ve(C),null)}var G=$CLJS.A(v);return $CLJS.ee(function(){var K=G;return n7(G,l)?$CLJS.R.j(K,$CLJS.p0,!0):K}(),u($CLJS.Lc(v)))}return null}},null,null)}($CLJS.Va(function(){if($CLJS.n(f)){var t=$CLJS.$n.h(k);return $CLJS.n(t)?$CLJS.jk.h(k):t}return f}())?null:$CLJS.zC(e,$CLJS.zj)?$CLJS.Tk.g(m7,
new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.v5(),new $CLJS.h(null,2,[$CLJS.uE,$CLJS.RE("Bin every 0.1 degrees"),$CLJS.tE,new $CLJS.h(null,2,[$CLJS.KE,$CLJS.JE,$CLJS.JE,.1],null)],null),new $CLJS.h(null,2,[$CLJS.uE,$CLJS.RE("Bin every 1 degree"),$CLJS.tE,new $CLJS.h(null,2,[$CLJS.KE,$CLJS.JE,$CLJS.JE,1],null)],null),new $CLJS.h(null,2,[$CLJS.uE,$CLJS.RE("Bin every 10 degrees"),$CLJS.tE,new $CLJS.h(null,2,[$CLJS.KE,$CLJS.JE,$CLJS.JE,10],null)],null),new $CLJS.h(null,2,[$CLJS.uE,$CLJS.RE("Bin every 20 degrees"),
$CLJS.tE,new $CLJS.h(null,2,[$CLJS.KE,$CLJS.JE,$CLJS.JE,20],null)],null)],null)):$CLJS.zC(c,$CLJS.Pj)&&!$CLJS.zC(e,$CLJS.oi)?$CLJS.Tk.g(m7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.v5(),new $CLJS.h(null,2,[$CLJS.uE,$CLJS.RE("10 bins"),$CLJS.tE,new $CLJS.h(null,2,[$CLJS.KE,$CLJS.sE,$CLJS.sE,10],null)],null),new $CLJS.h(null,2,[$CLJS.uE,$CLJS.RE("50 bins"),$CLJS.tE,new $CLJS.h(null,2,[$CLJS.KE,$CLJS.sE,$CLJS.sE,50],null)],null),new $CLJS.h(null,2,[$CLJS.uE,$CLJS.RE("100 bins"),$CLJS.tE,new $CLJS.h(null,2,
[$CLJS.KE,$CLJS.sE,$CLJS.sE,100],null)],null)],null)):null)}return $CLJS.Cf});$CLJS.wW.m(null,$CLJS.pG,function(a){return a});
$CLJS.wW.m(null,$CLJS.TK,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.VK);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.GE,$CLJS.p.h($CLJS.QE()),$CLJS.Gi,$CLJS.GA($CLJS.Gi,$CLJS.yB)(a)],null),a=$CLJS.k1.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,b,a],null);case "source/expressions":return k7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.yE.h(a))?k7(a):u7(a);default:return u7(a)}});
$CLJS.mra=function(){function a(e,f,k){k=$CLJS.Fe($CLJS.Tk.g($CLJS.yW,k));var l=jra(e,f),m=$CLJS.eg.j($CLJS.bh,$CLJS.Yl(function(t){return $CLJS.s2.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.Cf);m=$CLJS.Sk.g(m,l);k=$CLJS.n(k)?$CLJS.eg.j(k,$CLJS.hf.h($CLJS.yW),m):null;return $CLJS.YW.l(e,f,$CLJS.ME,$CLJS.H([$CLJS.ZE,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.x7=function(){function a(d,e){return $CLJS.ZE.h($CLJS.NW(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.nra=function(){function a(d,e){var f=$CLJS.S0.v(d,e,$CLJS.NW(d,e),new $CLJS.h(null,3,[$CLJS.A0,!1,$CLJS.G0,!1,$CLJS.w0,!1],null)),k=$CLJS.x7.g(d,e);return $CLJS.td(k)?$CLJS.Tk.g(function(l){return $CLJS.R.j(l,$CLJS.TZ,!0)},f):$CLJS.G3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
ora=function(){function a(d,e,f){var k=$CLJS.NW(d,e),l=$CLJS.E.g($CLJS.lC(f),$CLJS.eG)?$CLJS.Q0:$CLJS.S0;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.s2.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.pra=function(){function a(d,e,f){f=$CLJS.CZ.j(d,e,f);return ora.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();