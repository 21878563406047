var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var UI,WI,YI,jJ,nJ,vJ;$CLJS.TI=new $CLJS.M(null,"get-month","get-month",-369374731);UI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.VI=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);WI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.XI=new $CLJS.M(null,"iso","iso",-1366207543);YI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.ZI=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.$I=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.aJ=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.bJ=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.cJ=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.dJ=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.eJ=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.fJ=new $CLJS.M(null,"us","us",746429226);$CLJS.gJ=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.hJ=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.iJ=new $CLJS.M(null,"now","now",-1650525531);jJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.kJ=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.lJ=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.mJ=new $CLJS.M(null,"get-week","get-week",-888059349);
nJ=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.oJ=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.pJ=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.qJ=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.rJ=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.sJ=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.tJ=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.uJ=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);vJ=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.wJ=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.xJ=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.yJ=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.zJ=new $CLJS.M(null,"target","target",253001721);$CLJS.EF($CLJS.eJ,$CLJS.H([$CLJS.qt,$CLJS.kD,$CLJS.Ks,$CLJS.jH]));$CLJS.TE.m(null,$CLJS.bJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.UE(a);return $CLJS.vd(a)?(a=$CLJS.nu.g(a,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.$C,null,$CLJS.zD,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.A(a):a):a});$CLJS.xF($CLJS.bJ,$CLJS.jF);
for(var AJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,$CLJS.uJ],null)),BJ=null,CJ=0,DJ=0;;)if(DJ<CJ){var EJ=BJ.X(null,DJ);$CLJS.EF(EJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null),$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jH],null)]));$CLJS.xF(EJ,$CLJS.bJ);DJ+=1}else{var FJ=$CLJS.y(AJ);if(FJ){var GJ=FJ;if($CLJS.Ad(GJ)){var HJ=$CLJS.lc(GJ),Mga=$CLJS.mc(GJ),Nga=HJ,Oga=$CLJS.D(HJ);AJ=Mga;BJ=Nga;CJ=Oga}else{var IJ=$CLJS.A(GJ);$CLJS.EF(IJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.bF],null),$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jH],null)]));$CLJS.xF(IJ,$CLJS.bJ);AJ=$CLJS.B(GJ);BJ=null;CJ=0}DJ=0}else break}
for(var JJ=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.$I,$CLJS.TI,$CLJS.lJ,$CLJS.tJ,$CLJS.pJ,$CLJS.ZI,$CLJS.wJ],null)),KJ=null,LJ=0,MJ=0;;)if(MJ<LJ){var Pga=KJ.X(null,MJ);$CLJS.EF(Pga,$CLJS.H([$CLJS.qt,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));MJ+=1}else{var NJ=$CLJS.y(JJ);if(NJ){var OJ=NJ;if($CLJS.Ad(OJ)){var PJ=$CLJS.lc(OJ),Qga=$CLJS.mc(OJ),Rga=PJ,Sga=$CLJS.D(PJ);JJ=Qga;KJ=Rga;LJ=Sga}else{var Tga=$CLJS.A(OJ);$CLJS.EF(Tga,$CLJS.H([$CLJS.qt,
$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));JJ=$CLJS.B(OJ);KJ=null;LJ=0}MJ=0}else break}$CLJS.EF($CLJS.dJ,$CLJS.H([$CLJS.qt,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gH],null)]));
for(var QJ=$CLJS.y(new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.wJ,null,$CLJS.pJ,null,$CLJS.tJ,null,$CLJS.qJ,null,$CLJS.ZI,null,$CLJS.TI,null,$CLJS.$I,null,$CLJS.lJ,null],null),null)),RJ=null,SJ=0,TJ=0;;)if(TJ<SJ){var Uga=RJ.X(null,TJ);$CLJS.EF(Uga,$CLJS.H([$CLJS.qt,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));TJ+=1}else{var UJ=$CLJS.y(QJ);if(UJ){var VJ=UJ;if($CLJS.Ad(VJ)){var WJ=$CLJS.lc(VJ),Vga=$CLJS.mc(VJ),Wga=WJ,Xga=$CLJS.D(WJ);
QJ=Vga;RJ=Wga;SJ=Xga}else{var Yga=$CLJS.A(VJ);$CLJS.EF(Yga,$CLJS.H([$CLJS.qt,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));QJ=$CLJS.B(VJ);RJ=null;SJ=0}TJ=0}else break}$CLJS.X(vJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nk,$CLJS.XI,$CLJS.fJ,$CLJS.kJ],null));
$CLJS.GF($CLJS.mJ,$CLJS.H([$CLJS.qt,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vJ],null)],null)],null)],null)]));
$CLJS.X($CLJS.rJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,$CLJS.zE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,2,[$CLJS.xt,"valid timezone ID",$CLJS.zt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ej);return["invalid timezone ID: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.aH($CLJS.Jd,$CLJS.SI.tz.names())),$CLJS.JI],null)],null));
$CLJS.GF($CLJS.VI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rJ],null)],null)],null)],
null)]));$CLJS.xF($CLJS.VI,$CLJS.bJ);$CLJS.EF($CLJS.iJ,$CLJS.H([$CLJS.qt,$CLJS.cD]));$CLJS.X(YI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Re(function(b){return $CLJS.zC(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$C,$CLJS.zD],null))}],null)],null));
$CLJS.X(UI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yB,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YI],null)],null)],null)],null));
$CLJS.PG.g($CLJS.aJ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.xt,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.aJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.xt,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.HI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.di],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iH],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.xt,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.$v],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.di],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dH],null)],null)],null)],null)],null)],null));
$CLJS.TE.m(null,$CLJS.aJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.$v)?$CLJS.E.g(a,$CLJS.di)?$CLJS.zD:$CLJS.n($CLJS.wF($CLJS.iH,a))?$CLJS.$C:$CLJS.zD:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.nh($CLJS.EI,b))?$CLJS.$C:$CLJS.n($CLJS.nh($CLJS.FI,b))?$CLJS.$C:null:null;if($CLJS.n(a))return a;b=$CLJS.UE(b);b=$CLJS.vd(b)?$CLJS.eg.j($CLJS.bh,$CLJS.kf(function(c){return $CLJS.zC(c,$CLJS.Wj)}),b):b;return $CLJS.vd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.A(b):b});$CLJS.X(nJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.$v],null),$CLJS.Ks],null));
$CLJS.GF($CLJS.gJ,$CLJS.H([$CLJS.qt,$CLJS.zD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jH],null)],null)],null)],null)]));
$CLJS.EF($CLJS.My,$CLJS.H([$CLJS.qt,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hH],null)]));$CLJS.X(WI,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.nk,$CLJS.fH,$CLJS.Bi,$CLJS.mk,$CLJS.cJ,$CLJS.xJ,$CLJS.yJ,$CLJS.Gj,$CLJS.Hi,$CLJS.xi,$CLJS.jj,$CLJS.cH],null));$CLJS.X(jJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nk,$CLJS.XI,$CLJS.fJ,$CLJS.kJ],null));
$CLJS.GF($CLJS.sJ,$CLJS.H([$CLJS.qt,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jJ],null)],null)],
null)],null)]));