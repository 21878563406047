var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var IL,KL,ML,PL,UL;$CLJS.EL=function(a){return $CLJS.Wh($CLJS.q($CLJS.nC),a,$CLJS.tG)};$CLJS.FL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.GL=new $CLJS.M(null,"row","row",-570139521);$CLJS.HL=new $CLJS.M(null,"object-id","object-id",-754527291);IL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.JL=new $CLJS.M(null,"operators","operators",-2064102509);
KL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.LL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);ML=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.NL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.OL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
PL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.QL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.RL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.SL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.TL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
UL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.VL=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.X(KL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,$CLJS.EE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.lH],null)],null)],null));$CLJS.X(UL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,KL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yB,$CLJS.IE],null)],null)],null));$CLJS.X(ML,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.pG],null),UL,$CLJS.zE],null));
$CLJS.X(PL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.pG],null),KL,$CLJS.nL],null));
$CLJS.PG.g($CLJS.pG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.pG],null),KL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.nL,$CLJS.zE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.Pi,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.lC(a)):null},$CLJS.xt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.pB,PL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oB,ML],null)],null)],null));$CLJS.xF($CLJS.pG,$CLJS.tG);$CLJS.TE.m(null,$CLJS.pG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.GA($CLJS.Gi,$CLJS.yB)(b);return $CLJS.n(a)?a:$CLJS.fF});$CLJS.EF($CLJS.vB,$CLJS.H([$CLJS.zE]));$CLJS.TE.m(null,$CLJS.vB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.GA($CLJS.Gi,$CLJS.yB)(b);return $CLJS.n(a)?a:$CLJS.fF});
$CLJS.xF($CLJS.vB,$CLJS.tG);$CLJS.X(IL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,$CLJS.EE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.zE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.zE],null)],null)],null));$CLJS.PG.g($CLJS.eG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.eG],null),IL,$CLJS.Zj],null));
$CLJS.TE.m(null,$CLJS.eG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.GA($CLJS.Gi,$CLJS.yB)(b);return $CLJS.n(a)?a:$CLJS.fF});$CLJS.xF($CLJS.eG,$CLJS.tG);$CLJS.EF($CLJS.OF,$CLJS.H([$CLJS.qt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WK],null)],null)]));$CLJS.xF($CLJS.OF,$CLJS.tG);
$CLJS.EF($CLJS.kG,$CLJS.H([$CLJS.qt,$CLJS.fF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UK],null)],null)]));$CLJS.xF($CLJS.kG,$CLJS.tG);
$CLJS.X($CLJS.tG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,$CLJS.VE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.zt,function(){return["Valid reference, must be one of these clauses: ",$CLJS.It(", ",$CLJS.aH($CLJS.Jd,$CLJS.ml.g($CLJS.q($CLJS.nC),$CLJS.tG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.EL(a)}],null)],null));