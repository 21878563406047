var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var DH,EH,Ifa,GH,Jfa,Kfa,Lfa,HH,FH;$CLJS.BH=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.CH=function(a,b){return $CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
DH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.zC(b,$CLJS.$C)?$CLJS.mH:$CLJS.zC(b,$CLJS.ZD)?$CLJS.hH:$CLJS.zC(b,$CLJS.zD)?$CLJS.jH:null;return $CLJS.n(b)?$CLJS.wF(b,a):!0};
EH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.BH(function(d){return $CLJS.zC($CLJS.UE(d),$CLJS.kD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Pk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.UE($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(DH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
Ifa=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,1,[$CLJS.xt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.zt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Ej);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(EH(b))].join("")}],null),$CLJS.Te(EH)],null)],null)};
GH=function(a){var b=$CLJS.Q,c=Ifa(a);a=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.xt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ls,new $CLJS.h(null,1,[$CLJS.$n,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)],null)],null);
return new $CLJS.P(null,3,5,b,[$CLJS.ys,c,a],null)};Jfa=function(a){return $CLJS.Qd($CLJS.zF,$CLJS.hf.g(function(b){var c=$CLJS.UE(b),d=$CLJS.zC(c,$CLJS.fF);b=d?$CLJS.wF($CLJS.tG,b):d;return $CLJS.n(b)?$CLJS.Pj:c},a))};Kfa=function(a){a=$CLJS.CH(function(b){return!$CLJS.zC(b,$CLJS.kD)},$CLJS.hf.g($CLJS.UE,a));return $CLJS.zC(a,$CLJS.fF)?$CLJS.Wj:a};Lfa=function(a){return $CLJS.n($CLJS.Re(function(b){return $CLJS.zC($CLJS.UE(b),$CLJS.kD)},a))?Kfa(a):Jfa(a)};
HH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.IH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);FH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$E,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,FH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.UE(a);return $CLJS.Qe(function(d){return DH(d,c)},b)}],null)],null));
$CLJS.X(HH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ls,new $CLJS.h(null,1,[$CLJS.$n,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)],null));$CLJS.PG.g($CLJS.ds,GH($CLJS.ds));$CLJS.PG.g($CLJS.qt,GH($CLJS.qt));$CLJS.GF($CLJS.es,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ct,HH],null)]));$CLJS.GF($CLJS.KG,$CLJS.H([$CLJS.qt,$CLJS.WD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ct,HH],null)]));
for(var JH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,$CLJS.qt,$CLJS.es],null)),KH=null,LH=0,MH=0;;)if(MH<LH){var Mfa=KH.X(null,MH);$CLJS.xF(Mfa,$CLJS.IH);MH+=1}else{var NH=$CLJS.y(JH);if(NH){var OH=NH;if($CLJS.Ad(OH)){var PH=$CLJS.lc(OH),Nfa=$CLJS.mc(OH),Ofa=PH,Pfa=$CLJS.D(PH);JH=Nfa;KH=Ofa;LH=Pfa}else{var Qfa=$CLJS.A(OH);$CLJS.xF(Qfa,$CLJS.IH);JH=$CLJS.B(OH);KH=null;LH=0}MH=0}else break}$CLJS.TE.m(null,$CLJS.IH,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);return Lfa(a)});
$CLJS.EF($CLJS.QF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));$CLJS.xF($CLJS.QF,$CLJS.jF);
for(var QH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MG,$CLJS.HF,$CLJS.cG],null)),RH=null,SH=0,TH=0;;)if(TH<SH){var Rfa=RH.X(null,TH);$CLJS.EF(Rfa,$CLJS.H([$CLJS.qt,$CLJS.WD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));TH+=1}else{var UH=$CLJS.y(QH);if(UH){var VH=UH;if($CLJS.Ad(VH)){var WH=$CLJS.lc(VH),Sfa=$CLJS.mc(VH),Tfa=WH,Ufa=$CLJS.D(WH);QH=Sfa;RH=Tfa;SH=Ufa}else{var Vfa=$CLJS.A(VH);$CLJS.EF(Vfa,$CLJS.H([$CLJS.qt,$CLJS.WD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));QH=$CLJS.B(VH);RH=null;SH=0}TH=0}else break}
for(var XH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,$CLJS.nG,$CLJS.uG],null)),YH=null,ZH=0,$H=0;;)if($H<ZH){var Wfa=YH.X(null,$H);$CLJS.EF(Wfa,$CLJS.H([$CLJS.qt,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));$H+=1}else{var aI=$CLJS.y(XH);if(aI){var bI=aI;if($CLJS.Ad(bI)){var cI=$CLJS.lc(bI),Xfa=$CLJS.mc(bI),Yfa=cI,Zfa=$CLJS.D(cI);XH=Xfa;YH=Yfa;ZH=Zfa}else{var $fa=$CLJS.A(bI);$CLJS.EF($fa,$CLJS.H([$CLJS.qt,$CLJS.xj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));XH=$CLJS.B(bI);YH=null;ZH=0}$H=0}else break}$CLJS.EF($CLJS.NF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));
$CLJS.TE.m(null,$CLJS.NF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.zC($CLJS.UE(b),$CLJS.xj)&&$CLJS.zC($CLJS.UE(a),$CLJS.xj)?$CLJS.xj:$CLJS.WD});